import { gql } from "@apollo/client";

export const addPlace = () => gql`
  mutation AddPlace($input: MutationPlaceInput!) {
    addPlace(input: $input) {
      amenities
      category
      createdBy
      description
      geoJson
      geohash
      hashKey
      name
      rangeKey
      address
      createdAt
      coordinate {
        latitude
        longitude
      }
      flagged {
        confirmed
        userId
      }
      verified {
        confirmed
        userId
      }
    }
  }
`;

export const updatePlace = () => gql`
  mutation UpdatePlace($input: PlaceInput!) {
    updatePlace(input: $input) {
      amenities
      category
      createdBy
      description
      geoJson
      geohash
      hashKey
      name
      rangeKey
      address
      createdAt
      coordinate {
        latitude
        longitude
      }
      flagged {
        confirmed
        userId
        reason
      }
      verified {
        confirmed
        userId
      }
    }
  }
`;

export const updatePlaceVerified = () => gql`
  mutation updatePlaceVerified($input: PlaceVerifiedInput!) {
    updatePlaceVerified(input: $input) {
      address
    }
  }
`;

export const updateAdminPlaceVerified = () => gql`
  mutation updateAdminPlaceVerified($input: PlaceAdminVerifiedInput!) {
    updateAdminPlaceVerified(input: $input) {
      adminVerified {
        confirmed
        userId
      }
    }
  }
`;

export const uploadPhotosPlace = () => gql`
  mutation updatePlacePhotoRefs($input: UpdatePlacePhotoRefsInput!) {
    updatePlacePhotoRefs(input: $input) {
      photoRefs
    }
  }
`;

export const deletePlace = (rangeKey: string, hashKey: number) => gql`
  mutation DeletePlace{
    deletePlace(
      hashKey: ${hashKey},
      rangeKey: "${rangeKey}"){
        name
        rangeKey
      }
  }
`;
