import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './reducers';

const middleware = [thunk];

export default createStore(
    RootReducer,
    undefined,  // initial state
    compose(applyMiddleware(...middleware))
)