export default function StatHeading(props: {
  heading?: string;
  subheading?: string;
}) {
  const { heading, subheading } = props;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="text-dark fs-20 font-weight-bold">{heading}</div>
          <div className="text-dark fs-18">{subheading}</div>
        </div>
      </div>
    </div>
  );
}
