import { Layout, Collapse } from "antd";
import Logo from "../../../assets/images/logo.png";
import { useLocation, useHistory } from "react-router-dom";
import SideBarActive from "../../../common/consts/sidebarConfig";
import { LogoutOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Auth } from "aws-amplify";

const { Sider } = Layout;
const { Panel } = Collapse;
export default function SideBar() {
  const location = useLocation();
  const history = useHistory();

  const menu = [
    {
      title: "Dashboard",
      path: "/",
      leftIcon: "home",
      submenu: [
        {
          title: "Stats",
          path: "/",
        },
      ],
    },
    {
      title: "Charts",
      path: "/charts",
      leftIcon: "chart-bar",
      submenu: [
        {
          title: "All Charts",
          path: "/charts",
        },
      ],
    },
    {
      title: "Matches",
      path: "/matches",
      leftIcon: "equals",
      submenu: [
        {
          title: "All Matches",
          path: "/matches",
        },
      ],
    },
    {
      title: "USERS",
      path: "/users",
      leftIcon: "user",
      submenu: [
        {
          title: "All Users",
          path: "/users",
        },
        {
          title: "Reported",
          path: "/users/reported",
        },
      ],
    },
    {
      title: "PLACES",
      path: "/pals-places",
      leftIcon: "map-marker-alt",
      submenu: [
        {
          title: "All Places",
          path: "/pals-places",
        },
        {
          title: "Reported",
          path: "/pals-places/reported",
        },
      ],
    },
    {
      title: "CHECKINS",
      path: "/checkin/active",
      leftIcon: "map-marked",
      submenu: [
        {
          title: "Active Checkins",
          path: "/checkin/active",
        },
        {
          title: "Checkin History",
          path: "/checkin/history",
        },
      ],
    },
  ];

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <Sider
      width={"300px"}
      theme="light"
      className="px-0 px-lg-4 bg-primary"
      breakpoint="lg"
      // collapsedWidth="0"
      onBreakpoint={(broken) => {}}
      onCollapse={(collapsed, type) => {
        setIsCollapsed(collapsed);
      }}
      collapsed={isCollapsed}
      collapsible
    >
      {!isCollapsed ? (
        <img
          alt="pals-sidebar-logo"
          src={Logo}
          className="img-fluid pals-sidebar-logo"
        />
      ) : null}
      <Collapse
        expandIcon={(panelProps) => {
          return (
            <i
              className={`fas fa-chevron-${
                panelProps?.isActive ? "up" : "down"
              } pals-sider-arrow-icon text-dark`}
            ></i>
          );
        }}
        ghost
        expandIconPosition="right"
        onChange={(key) => {
          if (key !== undefined) {
            history.push(menu[+key].path);
          }
        }}
        defaultActiveKey={getDefaultActive()}
        accordion
      >
        {menu?.map((item, index) => {
          return (
            <Panel
              key={index}
              showArrow={
                isCollapsed
                  ? false
                  : item?.submenu?.length === 0 || !item?.submenu
                  ? false
                  : true
              }
              header={
                <div
                  className={`pals-sidebar-list text-dark ${
                    location.pathname === item.path ? "fw-bold" : ""
                  }`}
                >
                  <i
                    className={`fas fa-${item.leftIcon} text-dark pals-sidebar-icon`}
                  ></i>
                  {isCollapsed ? null : item.title}
                </div>
              }
            >
              {!isCollapsed &&
                item.submenu?.map((sub, subIndex) => {
                  return (
                    <div
                      onClick={() => {
                        history.push(sub.path);
                      }}
                      key={subIndex}
                      className={`pals-sidebar-list text-dark ms-4 mb-3 ps-1 ${
                        location.pathname === sub.path ? "fw-bold" : ""
                      }`}
                    >
                      {sub.title}
                    </div>
                  );
                })}
            </Panel>
          );
        })}
      </Collapse>
      <div
        className={`pals-sidebar-list text-dark justify-content-start ml-2 logout-button`}
        onClick={() => {
          Auth.signOut().then(() => {
            history.push("/login");
          });
        }}
      >
        <LogoutOutlined />
        {!isCollapsed && <div style={{ marginLeft: "10px" }}>Logout</div>}
      </div>
    </Sider>
  );

  function getDefaultActive() {
    let activeKeys: string[] = [];
    const index = SideBarActive.findIndex((ele) =>
      ele.paths?.includes(location.pathname)
    );
    if (index !== -1) {
      activeKeys.push(SideBarActive[index].key);
    }
    return activeKeys;
  }
}
