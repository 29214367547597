

import Amplify from '@aws-amplify/core';
import { Provider } from 'react-redux';
import { AppConfig } from './common/consts/aws-config';
import store from './redux/store';
import Routing from './routing';

function App() {

  Amplify.configure({
    ...AppConfig.amplifyConfig,
    Analytics: {
      disabled: true,
    },
  });

  return (
    <Provider store={store}>
      <Routing />
    </Provider>
  );
}

export default App;
