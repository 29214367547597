import { UniqueMatch } from "../common/consts/types";
import Match from "../models/match";

function getAllUniqueMatchDetails(matches: Match[]) {
  const allUniqueMatches: UniqueMatch[] = [];
  for (const i in matches) {
    if (Object.prototype.hasOwnProperty.call(matches, i)) {
      const match = matches[i];
      let currentMatchId = match.matchId;
      const doesMatchExists = allUniqueMatches.find(
        (e: UniqueMatch) => e.matchId === currentMatchId
      );
      if (!doesMatchExists) {
        allUniqueMatches.push({
          matchUsers: [
            {
              userId: match.matchWithId,
              userName: match.matchWithName,
            },
            {
              userId: match.userId,
              userName: "",
            },
          ],
          matchDate: match.matchDate,
          matchId: match.matchId,
          conversation: match.conversation || [],
        });
      }
      for (const j in matches) {
        if (Object.prototype.hasOwnProperty.call(matches, j)) {
          const updatedMatch = matches[j];
          if (i !== j && i >= j) {
            if (currentMatchId === updatedMatch.matchId) {
              const allUniqueIndex = allUniqueMatches?.findIndex(
                (e: UniqueMatch) => e.matchId === updatedMatch.matchId
              );
              if (allUniqueIndex !== -1) {
                const updatedMatchUsers =
                  allUniqueMatches[allUniqueIndex].matchUsers;
                updatedMatchUsers[1] = {
                  ...updatedMatchUsers[1],
                  userName: match.matchWithName,
                };
                allUniqueMatches[allUniqueIndex] = {
                  ...allUniqueMatches[allUniqueIndex],
                  matchUsers: updatedMatchUsers,
                };
              }
            }
          }
        }
      }
    }
  }

  return allUniqueMatches;
}

export { getAllUniqueMatchDetails };
