import User from "../../models/user";
import { SAVE_USERS, UPDATE_ROLE } from "./types";

export const saveUser = (
  payload: User[],
  nextToken: string,
  isAllUsers?: boolean
) => ({
  type: SAVE_USERS,
  payload,
  nextToken,
  isAllUsers: isAllUsers || false,
});
export const saveRole = (payload: string) => ({
  type: UPDATE_ROLE,
  payload,
});
