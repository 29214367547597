import React, { useCallback, useEffect } from "react";
import { Row, Col, Select } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import StatHeading from "../../Stats/components/StatHeading";
import {
  generateCitiesDataForState,
  generatePlacesOverTime,
  generateUsersOverTime,
  generateUsersPerState,
} from "../../../utils/chart";
import { useDispatch, useSelector } from "react-redux";
import { PlacesReducer, UserReducer } from "../../../common/consts/types";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getAdminPlaces, getAdminUsers } from "../../../graphql/queries";
import { PlaceItem } from "../../../models/placeInput";
import { useIsMounted } from "../../../common/hooks/useIsMounted";
import { savePlace } from "../../../redux/actions/placeActions";
import User from "../../../models/user";
import { saveUser } from "../../../redux/actions/userActions";
import {
  DATE_FORMAT,
  PI_CHART_BGS,
  PI_CHART_BOGS,
} from "../../../common/consts/config";
import { useState } from "react";
import DateRangePick from "../../../common/components/Chart/DateRangePick";
import moment from "moment";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  annotationPlugin
);

const { Option } = Select;

type LineType = {
  data: number[];
  labels: string[];
  average?: number;
};

export default function AllCharts() {
  const [selectedState, setSelectedState] = useState<string>("");
  const [isFirstTimeSelection, setIsFirstTimeSelection] =
    useState<boolean>(true);
  const [selectedStateData, setSelectedStateData] = useState({
    labels: [],
    data: [],
  });
  const [selectedStartDateUsers, setSelectedStartDateUsers] = useState(
    moment()
  );
  const [selectedEndDateUsers, setSelectedEndDateUsers] = useState(moment());

  const [selectedStartDatePlaces, setSelectedStartDatePlaces] = useState(
    moment()
  );
  const [selectedEndDatePlaces, setSelectedEndDatePlaces] = useState(moment());

  const [usersOverTime, setUsersOverTime] = useState<LineType>({
    labels: [],
    data: [],
    average: 0,
  });
  const [placesOverTime, setPlacesOverTime] = useState<LineType>({
    labels: [],
    data: [],
    average: 0,
  });
  const [usersPerState, setUsersPerState] = useState<LineType>({
    labels: [],
    data: [],
  });

  const commonPlugins = {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Graph Over Time",
    },
  };

  const commonOptions = {
    responsive: true,
    maintainAspectRatio: true,
  };

  const optionsUsersOverTime: any = {
    ...commonOptions,
    plugins: {
      ...commonPlugins,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: usersOverTime.average,
            yMax: usersOverTime.average,
            borderColor: "#9adbda",
            borderWidth: 2,
          },
        },
      },
    },
  };
  const optionsPlacesOverTime: any = {
    ...commonOptions,
    plugins: {
      ...commonPlugins,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: placesOverTime.average,
            yMax: placesOverTime.average,
            borderColor: "#9adbda",
            borderWidth: 2,
          },
        },
      },
    },
  };
  const users = useSelector(
    (state: { User: UserReducer }) => state?.User?.users || []
  );
  const places = useSelector(
    (state: { Place: PlacesReducer }) => state?.Place?.places || []
  );
  const isAllPlaces = useSelector(
    (state: { Place: PlacesReducer }) => state.Place?.isAllPlaces || false
  );
  const isAllUsers = useSelector(
    (state: { User: UserReducer }) => state?.User?.isAllUsers || false
  );

  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const usersOverTimeGraphData = {
    labels: usersOverTime.labels,
    datasets: [
      {
        label: "Users Over Time",
        data: usersOverTime.data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const placesOverTimeGraphData = {
    labels: placesOverTime.labels,
    datasets: [
      {
        label: "Places Over Time",
        data: placesOverTime.data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const usersOfStateGraphData = {
    labels: usersPerState.labels,
    datasets: [
      {
        label: "# of States",
        data: usersPerState.data,
        backgroundColor: PI_CHART_BGS,
        borderColor: PI_CHART_BOGS,
        borderWidth: 1,
      },
    ],
  };

  const usersOfCitiesOfStateGraphData = {
    labels: selectedStateData.labels,
    datasets: [
      {
        label: "# of Cities",
        data: selectedStateData.data,
        backgroundColor: PI_CHART_BGS,
        borderColor: PI_CHART_BOGS,
        borderWidth: 1,
      },
    ],
  };

  const fetchAllPlaces = useCallback(async () => {
    try {
      if (!isAllPlaces) {
        const response = await (API.graphql(
          graphqlOperation(getAdminPlaces(999999, ""))
        ) as GraphQLResult as {
          data: { adminPlaces: { items: PlaceItem[]; nextToken: string } };
        });
        const resPlaces: PlaceItem[] = response?.data?.adminPlaces?.items || [];
        const nextToken: string = response?.data?.adminPlaces?.nextToken;
        if (isMounted()) {
          if (resPlaces) {
            let placesToSave = resPlaces.slice();
            dispatch(savePlace(placesToSave, nextToken, true));
          }
        }
      }
    } catch (error) {}
  }, [isAllPlaces, isMounted, dispatch]);

  const fetchUsers = useCallback(async () => {
    try {
      if (!isAllUsers) {
        const response = await (API.graphql(
          graphqlOperation(getAdminUsers(1000, ""))
        ) as GraphQLResult as {
          data: { adminUsers: { items: User[]; nextToken: string } };
        });
        const resUsers: User[] = response?.data?.adminUsers?.items || [];
        const nextToken: string = response?.data?.adminUsers?.nextToken;
        if (isMounted()) {
          if (resUsers) {
            let usersToSave = resUsers.slice();
            dispatch(saveUser(usersToSave, nextToken, true));
          }
        }
      }
    } catch (error) {}
  }, [dispatch, isMounted, isAllUsers]);

  const fetchSelectedStateData = useCallback(() => {
    if (selectedState) {
      const selectedStateCity = generateCitiesDataForState(
        users,
        selectedState
      );
      setSelectedStateData(selectedStateCity as any);
    }
  }, [users, selectedState]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchAllPlaces();
  }, [fetchAllPlaces]);

  useEffect(() => {
    fetchSelectedStateData();
  }, [fetchSelectedStateData]);

  useEffect(() => {
    const tempUsersOverTime = generateUsersOverTime(users);
    setSelectedStartDateUsers(moment(tempUsersOverTime.labels[0], DATE_FORMAT));
    setSelectedEndDateUsers(
      moment(
        tempUsersOverTime.labels[tempUsersOverTime.labels?.length - 1],
        DATE_FORMAT
      )
    );
    setUsersOverTime(tempUsersOverTime);
  }, [users]);

  useEffect(() => {
    const tempUsersPerState = generateUsersPerState(users);
    setUsersPerState(tempUsersPerState);
  }, [users]);

  useEffect(() => {
    const tempPlacesOverTime = generatePlacesOverTime(places);
    setSelectedStartDatePlaces(
      moment(tempPlacesOverTime.labels[0], DATE_FORMAT)
    );
    setSelectedEndDatePlaces(
      moment(
        tempPlacesOverTime.labels[tempPlacesOverTime.labels?.length - 1],
        DATE_FORMAT
      )
    );
    setPlacesOverTime(tempPlacesOverTime);
  }, [places]);

  useEffect(() => {
    if (usersPerState.labels?.length > 0 && isFirstTimeSelection) {
      setIsFirstTimeSelection(false);
      setSelectedState(usersPerState.labels[0]);
    }
  }, [usersPerState, isFirstTimeSelection]);

  const allPlacesLabels = generatePlacesOverTime(places).labels;
  const minDatePlaces = moment(allPlacesLabels[0], DATE_FORMAT);
  const maxDatePlaces = moment(
    allPlacesLabels[allPlacesLabels.length - 1],
    DATE_FORMAT
  );

  const allUserLabels = generateUsersOverTime(users).labels;
  const minDateUsers = moment(allUserLabels[0], DATE_FORMAT);
  const maxDateUsers = moment(
    allUserLabels[allUserLabels.length - 1],
    DATE_FORMAT
  );

  return (
    <div className="">
      <Row>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 8, offset: 2 }}
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            marginBottom: "0px",
          }}
        >
          <StatHeading heading="Number of Users " subheading="Per State" />
          <div
            style={{
              height: "400px",
              width: "400px",
            }}
          >
            <Pie data={usersOfStateGraphData} />
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 8, offset: 2 }}
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            marginBottom: "20px",
          }}
        >
          <StatHeading heading="Number of Users " subheading="By State" />
          <div className="select-gap">
            <label>Select : </label>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              onSelect={(selected: any) => {
                setSelectedState(selected as string);
              }}
              value={selectedState}
            >
              {usersPerState?.labels?.map((state) => {
                return (
                  <Option value={state} key={state}>
                    {state}
                  </Option>
                );
              })}
            </Select>
          </div>

          <div
            style={{
              height: "400px",
              width: "400px",
            }}
          >
            <Pie
              data={usersOfCitiesOfStateGraphData}
              options={{ maintainAspectRatio: true }}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          lg={{ span: 20, offset: 2 }}
          style={{
            marginTop: "0px",
            marginBottom: "20px",
          }}
        >
          <StatHeading heading="Number of Users" subheading="Over Time" />
          <DateRangePick
            minDate={minDateUsers}
            maxDate={maxDateUsers}
            startDate={selectedStartDateUsers}
            endDate={selectedEndDateUsers}
            onChange={(dates: any) => {
              const tempUsersOverTime = generateUsersOverTime(
                users,
                dates[0],
                dates[1]
              );
              setUsersOverTime(tempUsersOverTime);
              setSelectedStartDateUsers(dates[0]);
              setSelectedEndDateUsers(dates[1]);
            }}
          />
          <Line
            height={"100px"}
            options={optionsUsersOverTime}
            data={usersOverTimeGraphData}
          />
        </Col>
        <Col
          className="gutter-row"
          style={{
            marginTop: "0px",
            marginBottom: "0px",
          }}
          xs={{ span: 24 }}
          lg={{ span: 20, offset: 2 }}
        >
          <StatHeading heading="Number of Places" subheading="Over Time" />
          <DateRangePick
            minDate={minDatePlaces}
            maxDate={maxDatePlaces}
            startDate={selectedStartDatePlaces}
            endDate={selectedEndDatePlaces}
            onChange={(dates: any) => {
              const tempPlacesOverTime = generatePlacesOverTime(
                places,
                dates[0],
                dates[1]
              );
              setPlacesOverTime(tempPlacesOverTime);
              setSelectedStartDatePlaces(dates[0]);
              setSelectedEndDatePlaces(dates[1]);
            }}
          />
          <Line
            options={optionsPlacesOverTime}
            data={placesOverTimeGraphData}
          />
        </Col>
      </Row>
    </div>
  );
}
