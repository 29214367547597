import { PlaceItem } from "../../models/placeInput";
import { SAVE_PLACES } from "./types";

export const savePlace = (
  payload: PlaceItem[],
  nextToken?: string,
  isAllPlaces?: boolean
) => ({
  type: SAVE_PLACES,
  payload,
  nextToken,
  isAllPlaces: isAllPlaces || false,
});
