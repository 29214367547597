import Match from "../../models/match";
import { SAVE_MATCHES } from "./types";

export const saveMatches = (
  payload: Match[],
  nextToken: string,
  isAllMatches?: boolean
) => ({
  type: SAVE_MATCHES,
  payload,
  nextToken,
  isAllMatches: isAllMatches || false,
});
