const messages = {
    somethingWrong: 'Something went wrong, Please try again later!',
    usernameReq: 'Username is required!',
    passwordReq: 'Password is required!',
    notAuthorized: 'Not Authorized to Login',
    locationNameReq: 'Location Name is required!',
    longReq: 'Longitude is required!',
    latReq: 'Latitude is required!',
    categoryReq: 'Category is required!',
    descReq: 'Description is required!',
    addressReq: 'Please enter a valid longitude and latitude!'
}

export default messages;