import { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserReducer } from '../../../common/consts/types';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { getAdminUsers } from '../../../graphql/queries';
import { saveUser } from '../../../redux/actions/userActions';
import User, { CheckIn } from '../../../models/user';
import moment from 'moment';
import { useIsMounted } from '../../../common/hooks/useIsMounted';
import Button from '../../../common/components/Button';
import UserCheckinList from './UserCheckinList';

export default function Checkin(props: {
    active?: boolean;
    history?: boolean;
}) {

    const { active } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

    const users = useSelector((state: { User: UserReducer }) => state?.User?.users || []);
    const apiNextToken = useSelector((state: { User: UserReducer }) => state?.User?.nextToken || '');

    const dispatch = useDispatch();

    const isMounted = useIsMounted();

    const fetchUsers = useCallback(async (loadMore?: boolean) => {
        try {
            if (!users || users?.length === 0 || loadMore) {
                if (loadMore)
                    setIsLoadingMore(true);
                else
                    setIsLoading(true);
                const response = await (API.graphql(graphqlOperation(getAdminUsers(999999, apiNextToken))) as GraphQLResult as { data: { adminUsers: { items: User[], nextToken: string } } });
                const resUsers: User[] = response?.data?.adminUsers?.items || [];
                const nextToken: string = response?.data?.adminUsers?.nextToken;
                if (isMounted()) {
                    if (resUsers) {
                        let usersToSave = resUsers.slice();
                        if (loadMore) {
                            usersToSave = [...users, ...usersToSave];
                        }
                        dispatch(saveUser(usersToSave, nextToken, true));
                    }
                    setIsLoading(false);
                    setIsLoadingMore(false);
                }
            }
        }
        catch (error) {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    }, [users, dispatch, isMounted, apiNextToken]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const historyColumns = [
        {
            title: '',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
        },
        {
            title: 'Owner',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => {
                return a?.name?.localeCompare(b?.name)
            },
        },
        {
            title: 'Range Key',
            dataIndex: 'rangeKey',
            key: 'rangeKey',
        },
        {
            title: 'Total Checkins',
            dataIndex: 'checkIns',
            key: 'checkIns',
            sorter: (a: any, b: any) => a?.checkIns?.length - b?.checkIns?.length,
            render: (text: any, record: any, index: any) => <span>{text?.length || ' 0 '}</span>,
        },
    ];
    const activeColumns = [
        {
            title: '',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'activeCheckIn',
            key: 'checkInDate',
            sorter: (a: User, b: User) => moment(a?.activeCheckIn?.checkInDate).unix() - moment(b?.activeCheckIn?.checkInDate).unix(),
            render: (text: CheckIn) => {
                return (
                    <div>{moment(text?.checkInDate).format('MM/DD/YYYY')}</div>
                )
            },
        },
        {
            title: 'User Id 1',
            dataIndex: 'rangeKey',
            key: 'rangeKey',
        },
        {
            title: 'User Id 2',
            dataIndex: 'activeCheckIn',
            key: 'userId2',
            render: (text: CheckIn) => {
                return (
                    <div>{text?.userId}</div>
                )
            },
        },
        {
            title: 'Pal Name 1',
            dataIndex: 'activeCheckIn',
            key: 'palName1',
            render: (text: CheckIn) => {
                return (
                    <div>{text?.selfPalName}</div>
                )
            },
        },
        {
            title: 'Pal Name 2',
            dataIndex: 'activeCheckIn',
            key: 'palName2',
            render: (text: CheckIn) => {
                return (
                    <div>{text?.palName}</div>
                )
            },
        },
        {
            title: 'Place ID',
            dataIndex: 'activeCheckIn',
            key: 'placeId',
            render: (text: CheckIn) => {
                return (
                    <div>{text?.placeId || ' - '}</div>
                )
            },
        },
        {
            title: 'Place Name',
            dataIndex: 'activeCheckIn',
            key: 'placeName',
            render: (text: CheckIn) => {
                return (
                    <div>{text?.placeName || ' - '}</div>
                )
            },
        }
    ];
    return (
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Table
                columns={active ? activeColumns : historyColumns}
                className="table-striped-rows"
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                scroll={{ x: true }}
                expandable={{
                    expandedRowRender: record => <UserCheckinList checkinData={record.checkIns || []} />,
                    expandRowByClick: true,
                    expandIcon: () => { return null },
                    rowExpandable: () => active ? false : true
                }}
                pagination={false}
                dataSource={active ? users.filter(ele => ele.activeCheckIn) : users}
                loading={isLoading}
                rowKey="rangeKey"
            />
            {
                apiNextToken ? (
                    <Button
                        title="Load More"
                        containerClassName="pals-table-loadmore"
                        titleClassName="pals-table-loadmore-text"
                        isLoading={isLoadingMore}
                        onClick={() => {
                            fetchUsers(true);
                        }}
                    />
                ) : null
            }
        </div>
    )
}