import { Tabs } from "antd";
import Pal from "../../../models/pal";
import UserTableExpand from "./UserTableExpand";
const { TabPane } = Tabs;

export default function PalTabs(props: {
  pals: Pal[];
  userRangeKey?: string;
  defaultPhotoRefId?: string;
}) {
  const { pals, userRangeKey, defaultPhotoRefId } = props;
  return (
    <>
      <Tabs defaultActiveKey="0">
        {pals.map((pal, index) => {
          return (
            <TabPane tab={pal.name} key={index}>
              <UserTableExpand
                rangeKey={userRangeKey || ""}
                pal={pal || undefined}
                defaultPhotoRefId={defaultPhotoRefId}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
}
