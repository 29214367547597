function SackFilled(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 146.14 146.14"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M146.12 118.74c0 15.13-14 27.4-31.3 27.4H31.33C14 146.14 0 133.87 0 118.74c0-5.53-3-49.27 54.77-82.2h36.57c57.45 32.75 54.78 76.78 54.78 82.2zM41.28 7.1a5.38 5.38 0 01-.77-2.53A4.58 4.58 0 0145.08 0h56a4.58 4.58 0 014.57 4.57 5.38 5.38 0 01-.77 2.53L91.34 27.4H54.8z"
          data-name="Layer 1"
          fill={props?.color || "#002d3b"}
        />
      </g>
    </svg>
  );
}

export default SackFilled;
