import { PalPlaceAmenities, PalPlaceCategory } from "../../models/placeInput";

export const MAP_CATEGORIES: {
  title: string;
  value: PalPlaceCategory;
}[] = [
  {
    title: "Dog Park",
    value: "DogPark",
  },
  {
    title: "Park",
    value: "Park",
  },
  {
    title: "Open Space",
    value: "OpenSpace",
  },
  {
    title: "Restaurant",
    value: "Restaurant",
  },
  {
    title: "Trail",
    value: "Trail",
  },
  {
    title: "Shop",
    value: "Shop",
  },
  {
    title: "Bar",
    value: "Bar",
  },
  {
    title: "Pet Store",
    value: "PetStore",
  },
  {
    title: "Brewery",
    value: "Brewery",
  },
  {
    title: "Winery",
    value: "Winery",
  },
  {
    title: "Cafe",
    value: "Cafe",
  },
  {
    title: "Beach",
    value: "Beach",
  },
  {
    title: "Lake",
    value: "Lake",
  },

  {
    title: "Hotel",
    value: "Hotel",
  },
  {
    title: "Grooming",
    value: "Grooming",
  },
];

export const MAP_AMENITIES: {
  title: string;
  icon: string;
  font: string;
  value: PalPlaceAmenities;
}[] = [
  {
    title: "Fenced",
    icon: "Fenced",
    font: "Fenced",
    value: "Fenced",
  },
  {
    title: "Water Bowls",
    icon: "tint",
    font: "FontAwesome5",
    value: "WaterBowls",
  },
  {
    title: "Treats",
    icon: "bone",
    font: "FontAwesome5",
    value: "Treats",
  },
  {
    title: "Poop Bags",
    icon: "sack",
    font: "Sack",
    value: "PoopBags",
  },
  {
    title: "Seating",
    icon: "chair",
    font: "FontAwesome5",
    value: "Seating",
  },
];
