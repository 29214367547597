import Spinner from "./Spinner";

export default function Button(props: {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  title?: string;
  isLoading?: boolean;
  containerClassName?: string;
  titleClassName?: string;
  disabled?: boolean;
}) {
  const {
    onClick,
    title,
    isLoading,
    containerClassName,
    titleClassName,
    disabled,
  } = props;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (!isLoading && onClick && !disabled) {
          onClick(e);
        }
      }}
      className={`pals-button${
        disabled ? "-disabled" : ""
      } mx-auto mt-4 flex-center-all ${containerClassName}`}
    >
      <div className={`pals-button-title${
        disabled ? "-disabled" : ""
      } ${titleClassName}`}>
        {isLoading ? <Spinner /> : title}
      </div>
    </div>
  );
}
