import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import Login from '../components/Login';
import PrivateRoute from './PrivateRoute';

function Routing() {

    const isLoggedIn = localStorage.getItem('cognitoUser') ? true : false;

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path="/login"
                    render={() => {
                        if (isLoggedIn)
                            return <Redirect to="/" />
                        return <Login />
                    }}
                />

                <PrivateRoute
                    path="/"
                    component={Dashboard}
                    redirectUrl="/login"
                />
            </Switch>
        </Router>
    )
}

export default Routing;