import moment from "moment";
import { CheckIn } from "../../../models/user";
import { Table } from 'antd';

export default function UserCheckinList(props: {
    checkinData: CheckIn[]
}) {
    const { checkinData } = props;
    const activeColumns = [
        {
            title: '',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'checkInDate',
            key: 'checkInDate',
            sorter: (a: CheckIn, b: CheckIn) => moment(a?.checkInDate).unix() - moment(b?.checkInDate).unix(),
            render: (text: string) => {
                return (
                    <div>{moment(text).format('MM/DD/YYYY')}</div>
                )
            },
        },
        {
            title: 'User Id 2',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Pal Name 1',
            dataIndex: 'selfPalName',
            key: 'selfPalName',
        },
        {
            title: 'Pal Name 2',
            dataIndex: 'palName',
            key: 'palName',
        },
        {
            title: 'Place ID',
            dataIndex: 'placeId',
            key: 'placeId',
            render: (text: string) => {
                return (
                    <div>{text || ' - '}</div>
                )
            },
        },
        {
            title: 'Place Name',
            dataIndex: 'activeCheckIn',
            key: 'placeName',
            render: (text: string) => {
                return (
                    <div>{text || ' - '}</div>
                )
            },
        }
    ];
    return (
        <Table
            columns={activeColumns}
            scroll={{ x: true }}
            pagination={false}
            dataSource={checkinData}
            rowKey="rangeKey"
        />
    )
}