import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MatchReducer,
  PlacesReducer,
  Stat,
  UserReducer,
} from "../../common/consts/types";
import { useIsMounted } from "../../common/hooks/useIsMounted";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
  getAdminMatches,
  getAdminPlaces,
  getAdminUsers,
} from "../../graphql/queries";
import User from "../../models/user";
import { saveUser } from "../../redux/actions/userActions";
import StatHeading from "./components/StatHeading";
import StatGridLayout from "./components/StatGridLayout";
import { filter } from "lodash";
import moment from "moment";
import loadingImage from "../../assets/images/loading.png";
import Spinner from "../../common/components/Spinner";
import { savePlace } from "../../redux/actions/placeActions";
import { PlaceItem } from "../../models/placeInput";
import Match from "../../models/match";
import { saveMatches } from "../../redux/actions/matchActions";
import UserZipPi from "./components/UserZipPi";

export default function Stats() {
  const users = useSelector(
    (state: { User: UserReducer }) => state?.User?.users || []
  );
  const usersApiNextToken = useSelector(
    (state: { User: UserReducer }) => state?.User?.nextToken || ""
  );
  const isAllUsers = useSelector(
    (state: { User: UserReducer }) => state?.User?.isAllUsers || false
  );
  const places = useSelector(
    (state: { Place: PlacesReducer }) => state?.Place?.places || []
  );
  const isAllPlaces = useSelector(
    (state: { Place: PlacesReducer }) => state.Place?.isAllPlaces || false
  );
  const matches = useSelector(
    (state: { Match: MatchReducer }) => state.Match?.matches || false
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userStats, setUserStats] = useState<Stat[]>([]);
  const [activityStatsToday, setActivityStatsToday] = useState<Stat[]>([]);
  const [activityStatsWeek, setActivityStatsWeek] = useState<Stat[]>([]);
  const [activityStatsLast, setActivityStatsLast] = useState<Stat[]>([]);
  const [activityStatsOverall, setActivityStatsOverall] = useState<Stat[]>([]);

  const [placesStats, setPlacesStats] = useState<Stat[]>([]);
  const [matchStats, setMatchStats] = useState<Stat[]>([]);

  const dispatch = useDispatch();

  const isMounted = useIsMounted();

  const fetchAllMatches = useCallback(async () => {
    if (!matches || matches?.length === 0) {
      try {
        const response = (await API.graphql(
          graphqlOperation(getAdminMatches(99999, ""))
        )) as GraphQLResult as {
          data: {
            adminMatches: {
              items: Match[];
              nextToken: string;
            };
          };
        };

        const resMatches: Match[] = response?.data?.adminMatches?.items || [];
        const nextToken: string = response?.data?.adminMatches?.nextToken;
        if (isMounted()) {
          if (resMatches) {
            let matchesToSave = resMatches?.slice();
            dispatch(saveMatches(matchesToSave, nextToken, true));
          }
        }
      } catch {}
    }
  }, [matches, isMounted, dispatch]);

  const fetchAllPlaces = useCallback(async () => {
    try {
      if (!isAllPlaces) {
        const response = await (API.graphql(
          graphqlOperation(getAdminPlaces(999999, ""))
        ) as GraphQLResult as {
          data: { adminPlaces: { items: PlaceItem[]; nextToken: string } };
        });
        const resPlaces: PlaceItem[] = response?.data?.adminPlaces?.items || [];
        const nextToken: string = response?.data?.adminPlaces?.nextToken;
        if (isMounted()) {
          if (resPlaces) {
            let placesToSave = resPlaces.slice();
            dispatch(savePlace(placesToSave, nextToken, true));
          }
        }
      }
    } catch (error) {}
  }, [isAllPlaces, isMounted, dispatch]);

  const fetchUsers = useCallback(
    async (loadMore?: boolean) => {
      try {
        if(isAllUsers){
          setIsLoading(false);
        }

        if (!isAllUsers) {
          setIsLoading(true);
          const response = await (API.graphql(
            graphqlOperation(getAdminUsers(100, usersApiNextToken))
          ) as GraphQLResult as {
            data: { adminUsers: { items: User[]; nextToken: string } };
          });
          const resUsers: User[] = response?.data?.adminUsers?.items || [];
          const nextToken: string = response?.data?.adminUsers?.nextToken;

          if (isMounted()) {
            
            if (resUsers) {
              // let usersToSave = resUsers.slice();

              // if (loadMore) {
              let usersToSave = [...users, ...(resUsers || [])?.slice()];
              // }

              dispatch(
                saveUser(usersToSave, nextToken, nextToken ? false : true)
              );
            }
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [dispatch, isMounted, isAllUsers, usersApiNextToken, users]
  );

  const generateUserStats = useCallback(() => {
    if (users && users?.length > 0) {

      const usersToday = filter(users, (o) =>
        moment((o.createdAt)).isSame(new Date(), 'day')
      );
      const usersThisWeek = filter(users, (o) =>
        moment(o.createdAt).isSame(new Date(), 'week')
      );
      const usersThisMonth = filter(users, (o) =>
        moment(o.createdAt).isSame(new Date(), 'month')
      );
      const usersWithProfileComplete = filter(
        users,
        (o) => o.pals && o.pals[0] && o.pals[0].profileComplete
      );
      const totalUsers = users?.length;

      const now = moment();
      const last = moment().subtract(1, "weeks");
      const seven = moment().subtract(7, "days");
      const today = new Date();

      const usersSwipedToday = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastSwipe) {
            const dateToCheck = new Date(user.activity.lastSwipe);
            if (today.toDateString() === dateToCheck.toDateString()) {
              return true;
            }
          }
          return false;
        });

      const usersSwipedThisWeek = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastSwipe) {
            const input = moment(user.activity.lastSwipe);
            if (now.isoWeek() === input.isoWeek()) {
              return true;
            }
          }
          return false;
        });

      const usersSwipedLastWeek = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastSwipe) {
            const input = moment(user.activity.lastSwipe);
            if (last.isoWeek() === input.isoWeek()) {
              return true;
            }
          }
          return false;
        });

      const usersSwipedLastSeven = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastSwipe) {
            const input = moment(user.activity.lastSwipe);
            if (input.isBetween(seven, now, "days", "[]")) {
              return true;
            }
          }
          return false;
        });

      const mapViewsToday = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMapSearch) {
            const dateToCheck = new Date(user.activity.lastMapSearch);
            if (today.toDateString() === dateToCheck.toDateString()) {
              return true;
            }
          }
          return false;
        });

      const messagesToday = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMessage) {
            const dateToCheck = new Date(user.activity.lastMessage);
            if (today.toDateString() === dateToCheck.toDateString()) {
              return true;
            }
          }
          return false;
        });

      const mapViewsThisWeek = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMapSearch) {
            const input = moment(user.activity.lastMapSearch);
            if (now.isoWeek() === input.isoWeek()) {
              return true;
            }
          }
          return false;
        });

      const messagesThisWeek = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMessage) {
            const input = moment(user.activity.lastMessage);
            if (now.isoWeek() === input.isoWeek()) {
              return true;
            }
          }
          return false;
        });

      const mapViewsLastWeek = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMapSearch) {
            const input = moment(user.activity.lastMapSearch);
            if (last.isoWeek() === input.isoWeek()) {
              return true;
            }
          }
          return false;
        });

      const mapViewsLastSeven = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMapSearch) {
            const input = moment(user.activity.lastMapSearch);
            if (input.isBetween(seven, now, "days", "[]")) {
              return true;
            }
          }
          return false;
        });

      const messagesLastSeven = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (user.activity && user.activity.lastMessage) {
            const input = moment(user.activity.lastMessage);
            if (input.isBetween(seven, now, "days", "[]")) {
              return true;
            }
          }
          return false;
        });

      const activityToday = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (
            user.activity &&
            (user.activity.lastSwipe ||
              user.activity.lastMapSearch ||
              user.activity.lastMessage)
          ) {
            const dateToCheckLastSwipe = user.activity.lastSwipe
              ? new Date(user.activity.lastSwipe)
              : null;
            const dateToCheckLastMapSearch = user.activity.lastMapSearch
              ? new Date(user.activity.lastMapSearch)
              : null;
            const dateToCheckLastMessage = user.activity.lastMessage
              ? new Date(user.activity.lastMessage)
              : null;

            if (
              (dateToCheckLastSwipe &&
                today.toDateString() === dateToCheckLastSwipe.toDateString()) ||
              (dateToCheckLastMapSearch &&
                today.toDateString() ===
                  dateToCheckLastMapSearch.toDateString()) ||
              (dateToCheckLastMessage &&
                today.toDateString() === dateToCheckLastMessage.toDateString())
            ) {
              return true;
            }
          }
          return false;
        });

      const activityThisWeek = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (
            user.activity &&
            (user.activity.lastSwipe ||
              user.activity.lastMapSearch ||
              user.activity.lastMessage)
          ) {
            const inputLastSwipe = user.activity.lastSwipe
              ? moment(user.activity.lastSwipe)
              : null;
            const inputLastMapSearch = user.activity.lastMapSearch
              ? moment(user.activity.lastMapSearch)
              : null;
            const inputLastMessage = user.activity.lastMessage
              ? moment(user.activity.lastMessage)
              : null;

            if (
              (inputLastSwipe && now.isoWeek() === inputLastSwipe.isoWeek()) ||
              (inputLastMapSearch &&
                now.isoWeek() === inputLastMapSearch.isoWeek()) ||
              (inputLastMessage && now.isoWeek() === inputLastMessage.isoWeek())
            ) {
              return true;
            }
          }
          return false;
        });

      const activityLastSeven = users
        .filter((user) => user.activity)
        .filter((user) => {
          if (
            user.activity &&
            (user.activity.lastSwipe ||
              user.activity.lastMapSearch ||
              user.activity.lastMessage)
          ) {
            const inputLastSwipe = user.activity.lastSwipe
              ? moment(user.activity.lastSwipe)
              : null;
            const inputLastMapSearch = user.activity.lastMapSearch
              ? moment(user.activity.lastMapSearch)
              : null;
            const inputLastMessage = user.activity.lastMessage
              ? moment(user.activity.lastMessage)
              : null;

            if (
              (inputLastSwipe &&
                inputLastSwipe.isBetween(seven, now, "days", "[]")) ||
              (inputLastMapSearch &&
                inputLastMapSearch.isBetween(seven, now, "days", "[]")) ||
              (inputLastMessage &&
                inputLastMessage.isBetween(seven, now, "days", "[]"))
            ) {
              return true;
            }
          }
          return false;
        });

      const tempUserStats: Stat[] = [
        {
          title: "Total Users",
          value: totalUsers,
          icon: "calculator",
        },
        {
          title: "New Users Today",
          value: usersToday.length || 0,
          icon: "plus-square",
        },
        {
          title: "New Users Weekly",
          value: usersThisWeek.length || 0,
          icon: "calendar",
        },
        {
          title: "New Users this Month",
          value: usersThisMonth.length || 0,
          icon: "calendar",
        },
        // {
        //   title: "Profiles Completed",
        //   value: usersWithProfileComplete?.length,
        //   totalValue: totalUsers,
        //   icon: "check",
        // },
      ];

      const tempActivityStatsToday: Stat[] = [
        {
          title: "Swipes Today",
          value: usersSwipedToday?.length,
          icon: "arrow-right",
        },
        {
          title: "Map Views Today",
          value: mapViewsToday?.length,
          icon: "map",
        },
        {
          title: "Messages Today",
          value: messagesToday?.length,
          icon: "comment",
        },
      ];

      const tempActivityStatsWeek: Stat[] = [
        {
          title: "Swipes This Week",
          value: usersSwipedThisWeek?.length,
          icon: "arrow-right",
        },
        {
          title: "Map Views This Week",
          value: mapViewsThisWeek?.length,
          icon: "map",
        },
        {
          title: "Messages This Week",
          value: messagesThisWeek?.length,
          icon: "comment",
        },
      ];

      const tempActivityStatsLast: Stat[] = [
        {
          title: "Swipes Last Seven Days",
          value: usersSwipedLastSeven?.length,
          icon: "arrow-right",
        },
        {
          title: "Map Last Seven Days",
          value: mapViewsLastSeven?.length,
          icon: "map",
        },
        {
          title: "Messages Last Seven Days",
          value: messagesLastSeven?.length,
          icon: "comment",
        },
      ];

      const tempActivityStatsOverall: Stat[] = [
        {
          title: "Activity Today",
          value: activityToday?.length,
          icon: "plus-square",
        },
        {
          title: "Activity This Week",
          value: activityThisWeek?.length,
          icon: "plus-square",
        },
        {
          title: "Activity Last Seven Days",
          value: activityLastSeven?.length,
          icon: "plus-square",
        },
      ];

      setUserStats(tempUserStats);
      setActivityStatsToday(tempActivityStatsToday);
      setActivityStatsWeek(tempActivityStatsWeek);
      setActivityStatsLast(tempActivityStatsLast);
      setActivityStatsOverall(tempActivityStatsOverall);
    }
  }, [users]);

  const generatePlacesStats = useCallback(() => {
    if (places && places?.length > 0) {
      const placesToday = filter(places, (o) =>
        moment(o.createdAt).isSameOrAfter(moment().subtract(1, "day"))
      );
      const placesThisWeek = filter(places, (o) =>
        moment(o.createdAt).isSameOrAfter(moment().subtract(1, "week"))
      );
      const tempPlacesStats: Stat[] = [
        {
          title: "Total Places",
          value: places?.length || 0,
          icon: "calculator",
        },
        {
          title: "New Places Today",
          value: placesToday?.length || 0,
          icon: "plus-square",
        },
        {
          title: "Places This Week",
          value: placesThisWeek?.length || 0,
          icon: "calendar",
        },
      ];
      setPlacesStats(tempPlacesStats);
    }
  }, [places]);

  const generateMatchesStats = useCallback(() => {
    const uniqueMatches = matches.filter(
      (v, i, a) => a.findIndex((t) => t.matchId === v.matchId) === i
    );

    if (uniqueMatches && uniqueMatches?.length > 0) {
      const matchesToday = filter(uniqueMatches, (o) =>
        moment(o.matchDate).isSameOrAfter(moment().subtract(1, "day"))
      );
      const matchesThisWeek = filter(uniqueMatches, (o) =>
        moment(o.matchDate).isSameOrAfter(moment().subtract(1, "week"))
      );
      const tempMatchStats: Stat[] = [
        {
          title: "Total Matches",
          value: uniqueMatches?.length || 0,
          icon: "calculator",
        },
        {
          title: "New Matches",
          value: matchesToday?.length,
          icon: "plus-square",
        },
        {
          title: "Matches This Week",
          value: matchesThisWeek?.length,
          icon: "calendar",
        },
      ];
      setMatchStats(tempMatchStats);
    }
  }, [matches]);

  useEffect(() => {
    generateUserStats();
  }, [generateUserStats]);

  useEffect(() => {
    generatePlacesStats();
  }, [generatePlacesStats]);

  useEffect(() => {
    generateMatchesStats();
  }, [generateMatchesStats]);

  useEffect(() => {
    fetchAllPlaces();
  }, [fetchAllPlaces]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchAllMatches();
  }, [fetchAllMatches]);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      {isLoading ? (
        <div className="w-100 flex-column d-flex justify-content-center align-items-center">
          <img style={{ maxWidth: "500px" }} alt="loading" src={loadingImage} />
          <div className="d-flex gap-2 align-items-center">
            <Spinner />
            <div className="fs-20 text-dark">Generating Stats..</div>
          </div>
        </div>
      ) : (
        <>
          <StatHeading
            heading="USER STATISTICS"
            subheading="Statistics of all the users."
          />
          <StatGridLayout stats={userStats} />
          <StatHeading
            heading="ACTIVITY STATISTICS OVERALL"
            subheading="Statistics of all the activity this week."
          />
          <StatGridLayout stats={activityStatsOverall} />
          <StatHeading
            heading="ACTIVITY STATISTICS TODAY"
            subheading="Statistics of all the activity today."
          />
          <StatGridLayout stats={activityStatsToday} />
          <StatHeading
            heading="ACTIVITY STATISTICS WEEKLY"
            subheading="Statistics of all the activity weekly."
          />
          <StatGridLayout stats={activityStatsWeek} />
          <StatHeading
            heading="ACTIVITY STATISTICS LAST SEVEN"
            subheading="Statistics of all the activity last seven."
          />
          <StatGridLayout stats={activityStatsLast} />
          <StatHeading
            heading="PLACE STATISTICS"
            subheading="Statistics of all the places."
          />
          <StatGridLayout stats={placesStats} />
          <StatHeading
            heading="MATCH STATISTICS"
            subheading="Statistics of all the matches."
          />
          <StatGridLayout stats={matchStats} />
        </>
      )}
    </div>
  );
}
