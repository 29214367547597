import React from "react";
import moment from "moment";
import { DatePicker } from "antd";

import { DATE_FORMAT } from "../../consts/config";
const { RangePicker } = DatePicker;

export default function DateRangePick(props: any) {
  const { startDate, endDate, onChange, minDate, maxDate } = props;

  function disabledDate(current: any) {
    if (current < moment(minDate)) {
      return true;
    } else if (current > moment(maxDate)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="w-100 d-flex justify-content-end pe-5 pb-4">
      <RangePicker
        value={[moment(startDate), moment(endDate)]}
        format={DATE_FORMAT}
        // disabledDate={disabledDate}
        onChange={(dates) => {
          if (dates) {
            onChange(dates);
          }
          else{
            onChange([moment(), moment()])
          }
        }}
      />
    </div>
  );
}
