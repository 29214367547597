export default function TextField(props: any) {
  const {
    rightIcon,
    containerClassName,
    inputClassName,
    rightIconContainerClassName,
    rightIconClassName,
    onRightIconClick,
    inputProps,
    type,
  } = props;
  return (
    <div
      className={`pals-textfield-container mx-auto mt-2 mb-1 ${containerClassName} ${
        type === "textarea" ? "mw-100" : ""
      }`}
    >
      {type === "textarea" ? (
        <textarea
          className={`pals-text-area ${type === "textarea" ? "w-100" : ""}`}
          {...inputProps}
        />
      ) : (
        <>
          <input
            type={type || "text"}
            className={`pals-input-box ${
              rightIcon ? "w-80" : "w-100"
            } ${inputClassName}`}
            placeholder="Username"
            {...inputProps}
          />
          {rightIcon ? (
            <div
              onClick={onRightIconClick}
              className={`pals-input-right flex-center-all ${rightIconContainerClassName}`}
            >
              <i
                className={`fas fa-${
                  rightIcon || "eye"
                } pals-input-right-icon ${rightIconClassName}`}
              ></i>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
