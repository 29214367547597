import { RootStateOrAny } from "react-redux";
import FenceFilled from "../../common/components/FenceFilled";
import SackFilled from "../../common/components/SackFilled";
import SackOutlined from "../../common/components/SackOutlined";
import { MAP_AMENITIES } from "../../common/consts/PlaceConstants";
import { PalPlaceAmenities } from "../../models/placeInput";

export default function Amenities(props: {
  selectedAmenities: PalPlaceAmenities[];
  onChange?: (selectedAmenity: PalPlaceAmenities) => void;
}) {
  const { selectedAmenities, onChange } = props;
  return (
    <div className="d-flex flex-row align-items-center justify-content-around">
      {MAP_AMENITIES?.map((amenity, index) => {
        const isActive = selectedAmenities?.includes(amenity.value);
        const currentColor = isActive ? "#002d3b" : "#C7D5D4";
        const iconStyles = {
          marginLeft: "10px",
          marginRight: "10px",
        };
        const iconProps = {
          name: amenity.icon,
          size: "30px",
          height: "30px",
          width: "30px",
          fontSize: "30px",
          color: currentColor,
          iconStyle: {},
          style: {
            ...iconStyles,
          },
        };

        return (
          <div
            key={index}
            className="p-3 cursor-pointer"
            onClick={() => {
              if (onChange) {
                onChange(amenity.value);
              }
            }}
          >
            {generateAmenties(isActive, index, amenity, iconProps)}
          </div>
        );
      })}
    </div>
  );

  function Icon(props: any) {
    const { name } = props;
    return (
      <i
        className={`fas fa-${name}`}
        style={{
          ...props,
          color: props.color,
        }}
      />
    );
  }

  function generateAmenties(
    isActive: boolean,
    index: number,
    amenity: RootStateOrAny,
    iconProps: RootStateOrAny
  ) {
    if (amenity.font === "FontAwesome5") {
      return <Icon type="solid" key={index} {...iconProps} />;
    } else if (amenity.font === "Fenced") {
      return <FenceFilled {...iconProps} key={index} />;
    } else if (amenity.font === "Sack") {
      if (isActive) {
        return <SackFilled {...iconProps} key={index} />;
      } else {
        return <SackOutlined {...iconProps} key={index} />;
      }
    } else return null;
  }
}
