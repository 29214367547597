import { gql } from "@apollo/client";

export const getAdminUsers = (
  // filter: TableUsersFilterInput,
  limit: number,
  nextToken: string
) => gql`
    query GetAdminUsers {
        adminUsers: getAdminUsers(limit: ${limit}, nextToken: "${
  nextToken || ""
}"){
            items {
                name
                createdAt
                hashKey
                rangeKey
                radius
                state
                city
                tourStatus
                quickStats {
                    totalSwipes
                    swipesToday
                    swipesThisWeek
                    swipesThisMonth
                }
                paid
                notifications
                birthDate
                state
                city
                phoneNumber
                userInstagram
                geoJson
                userInstagram
                favUserActivities {
                    source
                    value
                    displayName
                }
                favUserActivitiesPreferences {
                    source
                    value
                    displayName
                }
                favActivitiesPreferences {
                    source
                    value
                    displayName
                }
                activity {
                    lastSwipe
                    lastLogin
                    lastMapSearch
                    lastChangeLocation
                    lastPreferencesUpdate
                }
                checkIns{
                    checkInDate
                    userId
                    palName
                    selfPalName
                    placeId
                    placeName
                }
                activeCheckIn{
                    checkInDate
                    userId
                    palName
                    selfPalName
                    placeId
                    placeName
                }
                defaultPhotoRefId
                pals{
                    profileComplete
                    photoRefIds
                    gender
                    breed
                    instagram
                    size
                    registeredAnimalId
                    registeredLicenseNo
                    bio
                    specialNeeds
                    favActivities{
                        source
                        value
                    }
                    thisOrThats {
                        choice
                        thisOption
                        thatOption
                    }
                    name
                    profileComplete
                    nickname
                    size
                    gender
                    breed
                    bio
                    birthDate
                }
            }
            nextToken
        }
    }
`;

export const getAdminPlaces = (
  // filter: TableUsersFilterInput,
  limit: number,
  nextToken: string
) => gql`
    query GetAdminPlaces {
        adminPlaces: getAdminPlaces(limit: ${limit}, nextToken: "${
  nextToken || ""
}"){
            items {
                name
                createdAt
                address
                rangeKey
                photoRefs
                createdBy
                hashKey
                description
                category
                geoJson
                adminVerified {
                    confirmed
                    userId                   
                }
                verified {
                    confirmed
                    userId
                }
                flagged {
                    confirmed
                    userId
                    reason
                }
                coordinate {
                    latitude
                    longitude
                }
                amenities
            }
            nextToken
        }
    }
`;

export const getAdminMatches = (
  // filter: TableUsersFilterInput,
  limit: number,
  nextToken: string
) => gql`
    query GetAdminMatches {
        adminMatches: getAdminMatches(limit: ${limit}, nextToken: "${
  nextToken || ""
}"){
            items {
                matchDate
                matchId
                conversation{
                    timestamp
                    fromId
                    matchId
                    body{
                        text
                        displayName
                    }
                    viewed
                }
                userId
                matchWithId
                matchWithPhotoRef
                matchWithName
                latestMessage
            }
            nextToken
        }
    }
`;

export const getUserRangeKey = () => gql`
  query GetUserRangeKey($rangeKey: String!) {
    userRangeKey: getUserRangeKey(rangeKey: $rangeKey) {
      name
      displayName
      pals {
        name
      }
    }
  }
`;

export const getMatch = () => gql`
  query getMatch($userId: ID!, $matchId: ID!) {
    getMatch: getMatch(userId: $userId, matchId: $matchId) {
      conversation {
        body {
          displayName
          text
        }
      }
    }
  }
`;
export const getMessagesQuery = (matchId: string) => gql`
    query GetMessagesQuery {
        messages: getMessages(matchId: "${matchId}") {
            messages {
                timestamp
                body {
                    displayName
                    text
                    placeId
                    photoRef
                }
                matchId
                fromId
                liked
                viewed
            }
            nextToken
        }
    }
`;
export const getMessagesAfterQuery = (
  matchId: string,
  nextToken?: string | null
) => gql`
    query GetMessagesQuery {
        messages: getMessages(matchId: "${matchId}", after: "${
  nextToken || null
}") {
            messages {
                timestamp
                body {
                    displayName
                    text
                    placeId
                }
                matchId
                fromId
                liked
                viewed
            }
            nextToken
        }
    }
`;
