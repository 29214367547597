import { Md5 } from "ts-md5/dist/md5";
import { UserConfirmation } from "../models/placeInput";

export function parseJsonFromString(str: any): any {
  let strCopy = str;
  if (typeof str === "string") {
    strCopy = JSON.parse(strCopy);
    strCopy = parseJsonFromString(strCopy);
  }
  return strCopy;
}

export function getFlaggedPlacesUsers(flagged: UserConfirmation[]) {
  let flaggedUserIds: UserConfirmation[] = [];
  for (const flag of flagged) {
    if (flag?.confirmed) {
      flaggedUserIds.push(flag);
    }
  }
  return flaggedUserIds;
}

export function isFile(input: any) {
  if ("File" in window && input instanceof File) return true;
  else return false;
}

export async function getBase64(file: any) {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

export function convertBase64ToMd5Hash(base64string: string) {
  return Md5.hashStr(base64string);
}
