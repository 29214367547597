import { SAVE_USERS, UPDATE_ROLE } from "../actions/types";

const initialState = {
  users: [],
  nextToken: "",
  role: "",
};

const UserReducer = (
  state = initialState,
  action: {
    type: string;
    error: any;
    payload: any;
    nextToken: string;
    isAllUsers: boolean;
  }
) => {
  switch (action.type) {
    case SAVE_USERS:
      return {
        ...state,
        users: action.payload || [...state.users],
        nextToken: action.nextToken,
        isAllUsers: action.isAllUsers,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        role: action.payload
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserReducer;
