import { SAVE_PLACES } from "../actions/types";

const initialState = {
  places: [],
  nextToken: "",
  isAllPlaces: false,
};

const PlacesReducer = (
  state = initialState,
  action: {
    type: string;
    error: any;
    payload: any;
    nextToken: string;
    isAllPlaces?: boolean;
  }
) => {
  switch (action.type) {
    case SAVE_PLACES:
      return {
        ...state,
        places: action.payload || [...state.places],
        nextToken: action.nextToken || state?.nextToken,
        isAllPlaces: action.isAllPlaces || state.isAllPlaces || false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default PlacesReducer;
