export enum Environments {
  DEV = "dev",
  PROD = "prod",
}

export enum AuthenticationFlowType {
  USER_SRP_AUTH = "USER_SRP_AUTH",
}

export enum AwsRegion {
  US_EAST_1 = "us-east-1",
}

export enum AwsAppSyncAuthenticationType {
  COGNITO = "AMAZON_COGNITO_USER_POOLS",
}

export interface AmplifyConfig {
  userPoolId: string;
  userPoolWebClientId: string;
  region: AwsRegion;
  mandatorySignIn: boolean;
  authenticationFlowType: AuthenticationFlowType;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: AwsAppSyncAuthenticationType;
}

export interface LocationConfig {
  requestCityUrl: string;
}
export interface PhotoConfig {
  photoUrl: string;
  uploadS3Url: string;
  getS3Url: string;
}

const DevPhotoConfig: PhotoConfig = {
  photoUrl: "https://pals-photos-dev-alpha.s3.amazonaws.com/",
  uploadS3Url:
    "https://vkocr4fhdl.execute-api.us-east-1.amazonaws.com/Dev-Alpha/api/upload-pals-photo",
  getS3Url:
    "https://tmhpifdql4.execute-api.us-east-1.amazonaws.com/Dev-Alpha/api/get-pals-photo",
};

const ProdPhotoConfig: PhotoConfig = {
  photoUrl: "https://monitorpalsapp.com/",
  uploadS3Url:
    "https://api.monitorpalsapp.com/api/upload-pals-photo",
  getS3Url:
    "https://api.monitorpalsapp.com/api/get-pals-photo",
};

const DevLocationConfig: LocationConfig = {
  requestCityUrl:
    "https://tmhpifdql4.execute-api.us-east-1.amazonaws.com/Dev-Alpha/api/request-city",
};

const ProdLocationConfig: LocationConfig = {
  requestCityUrl:
    "https://3u4emo7a47.execute-api.us-east-1.amazonaws.com/Prod/api/request-city",
};

const DevAmplifyConfig: AmplifyConfig = {
  userPoolId: "us-east-1_hhKTnngVm",
  userPoolWebClientId: "gkupo7580qlb2kjdj0k64iere",
  region: AwsRegion.US_EAST_1,
  mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  aws_appsync_graphqlEndpoint:
    "https://hjqwur6czff6xiocwtqkk6fzja.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: AwsRegion.US_EAST_1,
  aws_appsync_authenticationType: AwsAppSyncAuthenticationType.COGNITO, // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  authenticationFlowType: AuthenticationFlowType.USER_SRP_AUTH,
};

const ProdAmplifyConfig: AmplifyConfig = {
  userPoolId: "us-east-1_lyhkqxkjX",
  userPoolWebClientId: "7fgc1eckboaprrisogfsem8rl9",
  region: AwsRegion.US_EAST_1,
  mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  aws_appsync_graphqlEndpoint:
    "https://ta72ve2g5ndp7bb3ci4lfd5bsi.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: AwsRegion.US_EAST_1,
  aws_appsync_authenticationType: AwsAppSyncAuthenticationType.COGNITO, // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  authenticationFlowType: AuthenticationFlowType.USER_SRP_AUTH,
};
export interface EnvironmentInterface {
  env: Environments;
  amplifyConfig: AmplifyConfig;
  photoConfig: PhotoConfig;
}
let instance: Environment;
export class Environment implements EnvironmentInterface {
  readonly env: Environments;
  readonly amplifyConfig: AmplifyConfig;
  readonly photoConfig: PhotoConfig;
  readonly locationConfig: LocationConfig;

  constructor() {
    this.env = this.getCurrentEnv();
    this.amplifyConfig = this.getAmplifyConfig(this.env);
    this.photoConfig = this.getPhotoConfig(this.env);
    this.locationConfig = this.getLocationConfig(this.env);
    instance = this;
  }

  getCurrentEnv() {
    if (process.env.NODE_ENV === "production") {
      return Environments.PROD;
    } else {
      return Environments.DEV;
    }
  }

  getPhotoConfig(env: Environments): PhotoConfig {
    return {
      photoUrl:
        env === Environments.PROD
          ? ProdPhotoConfig.photoUrl
          : DevPhotoConfig.photoUrl,
      uploadS3Url:
        env === Environments.PROD
          ? ProdPhotoConfig.uploadS3Url
          : DevPhotoConfig.uploadS3Url,
      getS3Url:
        env === Environments.PROD
          ? ProdPhotoConfig.getS3Url
          : DevPhotoConfig.getS3Url,
    };
  }

  getLocationConfig(env: Environments): LocationConfig {
    return {
      requestCityUrl:
        env === Environments.PROD
          ? ProdLocationConfig.requestCityUrl
          : DevLocationConfig.requestCityUrl,
    };
  }

  getAmplifyConfig(env: Environments): AmplifyConfig {
    return {
      userPoolId:
        env === Environments.PROD
          ? ProdAmplifyConfig.userPoolId
          : DevAmplifyConfig.userPoolId,
      userPoolWebClientId:
        env === Environments.PROD
          ? ProdAmplifyConfig.userPoolWebClientId
          : DevAmplifyConfig.userPoolWebClientId,
      region:
        env === Environments.PROD
          ? ProdAmplifyConfig.region
          : DevAmplifyConfig.region,
      mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      aws_appsync_graphqlEndpoint:
        env === Environments.PROD
          ? ProdAmplifyConfig.aws_appsync_graphqlEndpoint
          : DevAmplifyConfig.aws_appsync_graphqlEndpoint,
      aws_appsync_region: AwsRegion.US_EAST_1,
      aws_appsync_authenticationType: AwsAppSyncAuthenticationType.COGNITO, // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      // domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
      // path: '/',
      // OPTIONAL - Cookie expiration in days
      // expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      // secure: true
      // },
      // OPTIONAL - customized storage object
      // storage: MyStorage,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: AuthenticationFlowType.USER_SRP_AUTH,
      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },
      // OPTIONAL - Hosted UI configuration
      // oauth: {
      // domain: 'your_cognito_domain',
      // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // redirectSignIn: 'http://localhost:3000/',
      // redirectSignOut: 'http://localhost:3000/',
      // responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      // }
    };
  }
}

export const AppConfig = new Environment();
