import { range, startCase, uniqueId, update } from "lodash";
import { PlaceItem } from "../../../models/placeInput";
import { Table } from "antd";
import { getFlaggedPlacesUsers } from "../../../utils/commonFunc";
import {
  ADD_PLACE_RANGE_KEY,
  MAPBOX_STATIC_IMAGE_BASE_URL,
  MAPBOX_STATIC_IMAGE_SIZE,
  MAPBOX_STATIC_IMAGE_ZOOM,
  MAPBOX_TOKEN,
} from "../../../common/consts/config";
import {
  updateAdminPlaceVerified,
  updatePlaceVerified,
} from "../../../graphql/mutations";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../../common/components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { PlacesReducer, UserReducer } from "../../../common/consts/types";
import { useIsMounted } from "../../../common/hooks/useIsMounted";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getAdminUsers } from "../../../graphql/queries";
import User from "../../../models/user";
import { saveUser } from "../../../redux/actions/userActions";
import Button from "../../../common/components/Button";
import SinglePlacePhoto from "./SinglePlacePhoto";
import { savePlace } from "../../../redux/actions/placeActions";
import { Auth } from "aws-amplify";

export default function PlaceTableExpand(props: {
  place: PlaceItem;
  reported: boolean | undefined;
  setIsAddingPlace: (addingPlace: boolean) => void;
  setEditPlace: (editPlace: PlaceItem | null) => void;
  editIndex: number;
  setEditIndex: (editIndex: number) => void;
  fetchAllPlaces: any;
}) {
  const users = useSelector(
    (state: { User: UserReducer }) => state?.User?.users || []
  );
  const places = useSelector(
    (state: { Place: PlacesReducer }) => state?.Place?.places || []
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const role = useSelector((state: { User: UserReducer }) => state.User?.role);

  const [success, setSuccess] = useState<boolean>(false);

  const fetchUsers = useCallback(async () => {
    try {
      if (!users || users?.length === 0) {
        setIsLoading(true);
        const response = await (API.graphql(
          graphqlOperation(getAdminUsers(999999, ""))
        ) as GraphQLResult as {
          data: { adminUsers: { items: User[]; nextToken: string } };
        });
        const resUsers: User[] = response?.data?.adminUsers?.items || [];
        const nextToken: string = response?.data?.adminUsers?.nextToken;
        if (isMounted()) {
          setIsLoading(false);
          if (resUsers) {
            let usersToSave = resUsers.slice();
            dispatch(saveUser(usersToSave, nextToken));
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [users, dispatch, isMounted]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const {
    place,
    reported,
    setIsAddingPlace,
    setEditPlace,
    setEditIndex,
    editIndex,
    fetchAllPlaces,
  } = props;
  const hashKey = place?.hashKey || 0;
  const rangeKey = place?.rangeKey;

  const reportedUsersCol = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      width: "500px",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
  ];
  if (reported) {
    return (
      <Table
        columns={reportedUsersCol}
        className="table-striped-rows"
        scroll={{ x: true }}
        pagination={false}
        dataSource={getFlaggedPlacesUsers(place.flagged)}
        rowKey={(obj) => uniqueId()}
      />
    );
  }

  async function updatePlace() {
    try {
      setIsVerifying(true);
      await API.graphql(
        graphqlOperation(updatePlaceVerified(), {
          input: {
            hashKey: hashKey,
            rangeKey: rangeKey,
            verified: [
              ...(place?.verified || []),
              ...Array(10).fill({
                confirmed: true,
                reason: null,
                userId: ADD_PLACE_RANGE_KEY,
              }),
            ],
          },
        })
      );
      const user = await Auth.currentSession();
      await API.graphql(
        graphqlOperation(updateAdminPlaceVerified(), {
          input: {
            hashKey: hashKey,
            rangeKey: rangeKey,
            adminVerified: {
              confirmed: true,
              reason: null,
              userId: user.getIdToken()?.payload?.sub,
            },
          },
        })
      );
      setSuccess(true);
    } catch (error) {
    } finally {
      let placesCopy = places?.slice();
      const currPlaceIndex = placesCopy?.findIndex(
        (e) => e.rangeKey === place.rangeKey
      );
      if (currPlaceIndex !== -1) {
        placesCopy[currPlaceIndex] = {
          ...placesCopy[currPlaceIndex],
          verified: [
            ...(placesCopy[currPlaceIndex]?.verified || []),
            ...Array(10).fill({
              confirmed: true,
              reason: null,
              userId: ADD_PLACE_RANGE_KEY,
            }),
          ],
        };
      }
      dispatch(savePlace(placesCopy, undefined, undefined));
      setIsVerifying(false);
    }
  }

  function checkLocationVerified() {
    const totalNotVerified = (place?.verified || [])?.filter(
      (e) => !e.confirmed
    )?.length;
    const totalVerified = place?.verified?.length - totalNotVerified;

    if (totalVerified >= 10) {
      return true;
    } else {
      return false;
    }
  }

  const createdByUser = users?.find(
    (user) => user.rangeKey === place.createdBy
  );

  const isVerified = checkLocationVerified() || success;

  return (
    <div className="ms-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        {role === "SuperAdmin" ? (
        <Button
          title={isVerified ? "Verified" : "Verify Place"}
          disabled={isVerified}
          isLoading={isVerifying}
          containerClassName="pals-table-loadmore mx-0"
          titleClassName="pals-table-loadmore-text"
          onClick={() => {
            updatePlace();
          }}
        />
        ) : <p></p>}
        <Button
          title="Edit Place"
          containerClassName="pals-table-loadmore ms-auto me-0"
          titleClassName="pals-table-loadmore-text"
          onClick={() => {
            setIsAddingPlace(true);
            setEditPlace(place);
            setEditIndex(editIndex);
          }}
        />
      </div>
      <p className="text-dark">
        Created By: {isLoading ? <Spinner /> : createdByUser?.name || " - "}
      </p>
      <p className="text-dark">
        Created By Phone Number:{" "}
        {isLoading ? (
          <Spinner />
        ) : role === "Intern" ? (
          " - "
        ) : (
          createdByUser?.phoneNumber || " - "
        )}
      </p>
      <p className="text-dark">
        Created By User Instagram:{" "}
        {isLoading ? <Spinner /> : createdByUser?.userInstagram || " - "}
      </p>
      <p className="text-dark">
        Created By Pal:{" "}
        {isLoading ? <Spinner /> : createdByUser?.pals?.[0].name || " - "}
      </p>
      <p className="text-dark">
        Created By Pal Instagram:{" "}
        {isLoading ? <Spinner /> : createdByUser?.pals?.[0].instagram || " - "}
      </p>
      <p className="text-dark">Address: {place.address}</p>
      <p className="text-dark">Description: {place.description}</p>
      <p className="text-dark">
        Amenities:{" "}
        {place?.amenities
          ? place?.amenities?.map((amen) => startCase(amen))?.join(", ")
          : "N/A"}
        {place?.amenities?.length === 0 ? "No Amenities Selected" : null}
      </p>
      <p className="text-dark">
        Coordinates: Longitude: {place?.coordinate?.longitude}, Latitude:{" "}
        {place?.coordinate?.latitude}
      </p>
      <p className="text-dark">
        Map Image
        <div
          style={{ width: "30%" }}
          className="d-flex align-items-center justify-content-center"
        >
          {imageLoading ? <Spinner /> : null}
          <img
            src={`${MAPBOX_STATIC_IMAGE_BASE_URL}(%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${place?.coordinate?.longitude}%2C${place?.coordinate?.latitude}%5D%7D)/${place?.coordinate?.longitude},${place?.coordinate?.latitude},${MAPBOX_STATIC_IMAGE_ZOOM},0/${MAPBOX_STATIC_IMAGE_SIZE}?access_token=${MAPBOX_TOKEN}`}
            width="100%"
            alt="map"
            onLoad={() => {
              setImageLoading(false);
            }}
          />
        </div>
      </p>
      {place?.photoRefs && place.photoRefs?.length > 0 ? (
        <p className="text-dark">
          Photo Refs
          <div className="d-flex flex-wrap gap-3">
            {place?.photoRefs?.map((ref, index) => {
              return (
                <SinglePlacePhoto
                  item={ref}
                  key={index}
                  rangeKey={place.rangeKey}
                />
              );
            })}
          </div>
        </p>
      ) : null}
    </div>
  );
}
