import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MatchReducer,
  UniqueMatch,
  UserReducer,
} from "../../../common/consts/types";
import { useCallback, useEffect, useState } from "react";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getAdminMatches } from "../../../graphql/queries";
import moment from "moment";
import { useIsMounted } from "../../../common/hooks/useIsMounted";
import Match from "../../../models/match";
import { saveMatches } from "../../../redux/actions/matchActions";
import { getAllUniqueMatchDetails } from "../../../utils/match";
import RenderUserName from "./RenderUserName";
import MatchConversationExpand from "./MatchConversationExpand";

export default function Matches() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const isMounted = useIsMounted();

  const matches = useSelector(
    (state: { Match: MatchReducer }) => state.Match?.matches || false
  );
  const isAllMatches = useSelector(
    (state: { Match: MatchReducer }) => state.Match?.isAllMatches || false
  );
  const apiNextToken = useSelector(
    (state: { Match: MatchReducer }) => state?.Match?.nextToken || ""
  );
  const role = useSelector((state: { User: UserReducer }) => state.User?.role);

  const generateMatchArray = getAllUniqueMatchDetails(matches);

  const fetchAllMatches = useCallback(
    async (loadMore?: boolean) => {
      if (isAllMatches) {
        setIsLoading(false);
      }
      if (!isAllMatches) {
        setIsLoading(true);
        try {
          const response = (await API.graphql(
            graphqlOperation(getAdminMatches(100, apiNextToken))
          )) as GraphQLResult as {
            data: {
              adminMatches: {
                items: Match[];
                nextToken: string;
              };
            };
          };

          const resMatches: Match[] = response?.data?.adminMatches?.items || [];
          const nextToken: string = response?.data?.adminMatches?.nextToken;
          if (isMounted()) {
            if (resMatches) {
              let matchesToSave = [...matches, ...(resMatches || [])?.slice()];
              dispatch(
                saveMatches(matchesToSave, nextToken, nextToken ? false : true)
              );
            }
          }
        } catch {
          setIsLoading(false);
        }
      }
    },
    [matches, isMounted, dispatch, apiNextToken, isAllMatches]
  );

  useEffect(() => {
    fetchAllMatches();
  }, [fetchAllMatches]);

  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Match Id",
      dataIndex: "matchId",
      key: "matchId",
      render: (id: any) => {
        return <span>{id.substring(0,8)}</span>;
      },
    },
    {
      title: "User Name",
      dataIndex: "matchUsers",
      key: "matchWithName",
      sorter: (a: any, b: any) => {
        return a?.matchUsers[0]?.userName?.localeCompare(
          b?.matchUsers[0]?.userName
        );
      },
      render: (d: any) => {
        return <RenderUserName d={d} index={0} />;
      },
    },
    {
      title: "Match With Name",
      dataIndex: "matchUsers",
      key: "userId",
      sorter: (a: any, b: any) => {
        return a?.matchUsers[1]?.userName?.localeCompare(
          b?.matchUsers[1]?.userName
        );
      },
      render: (d: any) => {
        return <RenderUserName d={d} index={1} />;
      },
    },
    {
      title: "Match Date",
      dataIndex: "matchDate",
      key: "matchDate",
      sorter: (a: UniqueMatch, b: UniqueMatch) =>
        moment(a?.matchDate).unix() - moment(b?.matchDate).unix(),
      render: (text: string) => {
        return <div>{moment(text).format("MM/DD/YYYY")}</div>;
      },
    },
    {
      title: "Has Conversation",
      dataIndex: "conversation",
      key: "conversation",
      sorter: (a: UniqueMatch, b: UniqueMatch) => {
        return (a?.conversation?.length > 0 ? "Yes" : "No")?.localeCompare(
          b?.conversation?.length > 0 ? "Yes" : "No"
        );
      },
      render: (text: any) => {
        return <div>{text?.length > 0 ? "Yes" : "No"}</div>;
      },
    },
  ];

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Table
        columns={columns}
        className="table-striped-rows"
        scroll={{ x: true }}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-dark" : "table-row-light"
        }
        expandable={{
          expandedRowRender: (record, index) => (
            <MatchConversationExpand match={record} />
          ),

          expandRowByClick: true,
          rowExpandable: (record) => role === "SuperAdmin",
          expandIcon: () => {
            return null;
          },
        }}
        pagination={{
          defaultPageSize: 50,
        }}
        dataSource={generateMatchArray}
        loading={isLoading}
        rowKey="matchId"
      />
    </div>
  );
}
