import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { Auth } from "aws-amplify";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PalsLogo from "../../assets/images/logo.png";
import Button from "../../common/components/Button";
import TextField from "../../common/components/TextField";
import messages from "../../common/consts/messages";
import { AmplifyResponseType } from "../../common/consts/types";
import { saveRole } from "../../redux/actions/userActions";
import { getRole } from "../../utils/auth";

function Login() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();

  async function AmplifySigninCall(
    username: string | UsernamePasswordOpts,
    password: string | undefined
  ) {
    // common response containing api response and api error
    let response: AmplifyResponseType = {
      error: false,
      response: {},
    };

    try {
      response = {
        ...response,
        response: await Auth.signIn(username, password),
      };
    } catch (error) {
      response = {
        ...response,
        error,
      };
    }
    return response;
  }

  useEffect(() => {
    if (isValidated) {
      validate();
    }
  }, [isValidated, username, password]);

  return (
    <div className="container-fluid bg-primary mh-100vh">
      <div className="row pb-5">
        <div className="col-12 text-center">
          <img
            alt="pals-logo"
            className="img-fluid pals-login-logo"
            src={PalsLogo}
          />
          <div className="pals-login-content">Monitoring App</div>
        </div>
        <div className="col-12 text-center mt-5 pt-4">
          <TextField
            inputProps={{
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setUsername(e.target.value);
              },
            }}
          />
          <TextField
            rightIcon="eye"
            onRightIconClick={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
            inputProps={{
              placeholder: "Password",
              type: isPasswordVisible ? "text" : "password",
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
              },
            }}
          />
        </div>
        <div className="col-12 text-center">
          <div className="pals-error mt-3">{error}</div>
          <Button title="LOGIN" onClick={login} isLoading={isLoading} />
          <div className="pals-login-forgot mt-3">Forgot Password?</div>
        </div>
      </div>
    </div>
  );

  function validate() {
    let validated = false;
    setIsValidated(true);
    if (!username) {
      setError(messages.usernameReq);
    } else if (!password) {
      setError(messages.passwordReq);
    } else {
      setError("");
      validated = true;
    }
    return validated;
  }

  async function login() {
    const validated = validate();
    if (validated) {
      try {
        setError("");
        setIsLoading(true);
        const response = await AmplifySigninCall(username, password);
        if (response?.error?.message) {
          setError(response?.error?.message);
        } else if (response?.response) {
          setIsLoading(false);
          const sessionInfo =
            response.response?.signInUserSession?.getIdToken()?.payload;
          if (
            sessionInfo["cognito:groups"]?.includes("SuperAdmin") ||
            sessionInfo["cognito:groups"]?.includes("Admin") ||
            sessionInfo["cognito:groups"]?.includes("Intern") || 
            sessionInfo["cognito:groups"]?.includes("SuperAdmin") 
          ) {
            localStorage.setItem(
              "cognitoUser",
              JSON.stringify(response.response)
            );
            localStorage.setItem(
              "loginTime",
              JSON.stringify(moment().toDate())
            );
            dispatch(saveRole(getRole(sessionInfo)));
            history.push("/");
          } else {
            setError(messages.notAuthorized);
          }
        }
      } catch (error) {
        // unexceptional erro occured
        console.log(error)
        setIsLoading(false);
        setError(messages.somethingWrong);
      }
    }
  }
}

export default Login;
