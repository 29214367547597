import { Auth } from "aws-amplify";
import { AppConfig } from "../common/consts/aws-config";
import { PhotoModel } from "../models/photo";

// function async
export const uploadPlacePhoto = (body: PhotoModel, palsIdToken: any) => {
  const UPLOAD_S3_URL = AppConfig.photoConfig.uploadS3Url;
  return fetch(UPLOAD_S3_URL, {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${palsIdToken}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      // error occured while upload place photo
    });
};

export default async function photoMiddleware(middlewareProps: {
  userId: string;
  photoRefId: string;
  photoType?: string;
}) {
  const { userId, photoRefId, photoType } = middlewareProps;
  const user = await Auth.currentSession();
  const GET_S3_URL = AppConfig.photoConfig.getS3Url;
  let body: any = JSON.stringify({
    userId,
    photoRefId,
    photoType: photoType,
  });
  if (!photoType) {
    delete body.photoType;
  }
  return fetch(GET_S3_URL, {
    body: body,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.getIdToken().getJwtToken()}`, // get from amplify jwt token
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
    })
    .catch((error) => {});
}

export async function getPhotoUrl(
  rangeKey: string,
  photoRef: string,
  type?: string,
  path?: string,
  folder?: string
) {
  const currentFolder = folder || "users";
  const currentPath = path || "pals";
  const GET_S3_URL = AppConfig.photoConfig.photoUrl;
  const ImageURI = `${GET_S3_URL}${currentFolder}/${rangeKey}/${
    currentFolder === "places" ? "" : `${currentPath}/`
  }${photoRef}.jpeg`;
  const user = await Auth.currentSession();
  let typeVar = "uri";

  if (type) {
    typeVar = type;
  }


  if (process.env.NODE_ENV === "production") {
    return {
      [typeVar]: ImageURI,
      headers: {
        Authorization: `${user.getIdToken().getJwtToken()}`,
      },
    };
  } else {
    return {
      [typeVar]: ImageURI,
    };
  }
}
