import { useCallback, useEffect, useState } from "react";
import Spinner from "../../../common/components/Spinner";
import { IMAGE_BASE_64 } from "../../../common/consts/config";
import { useIsMounted } from "../../../common/hooks/useIsMounted";
import photoMiddleware from "../../../utils/photoMiddleware";

export default function PalImage(props: {
    photoRefId: string;
    rangeKey: string;
}) {
    const { photoRefId, rangeKey } = props;

    const [photo, setPhoto] = useState<string>('');
    const [loadingImage, setLoadingImage] = useState<boolean>(false);
    const isMounted = useIsMounted();

    const getPhotoBase64 = useCallback(async () => {
        try {
            if (photoRefId) {
                setLoadingImage(true);
                const response = await photoMiddleware({
                    userId: rangeKey,
                    photoRefId: photoRefId
                });
                if (isMounted()) {
                    setLoadingImage(false);
                    if (response) {
                        setPhoto(`${IMAGE_BASE_64}${response}`)
                    }
                }
            }
        }
        catch (error) {
            setLoadingImage(false);
        }
    }, [photoRefId, isMounted, rangeKey]);

    useEffect(() => {
        getPhotoBase64();
    }, [getPhotoBase64]);


    return (
        <>
            {
                loadingImage ? (
                    <div className="pal-table-image flex-center-all bg-light">
                        <Spinner />
                    </div>
                ) : (
                    <img
                        src={photo ? photo : "https://via.placeholder.com/160/DDDDDD/808080"}
                        alt="single-pal"
                        className="img-fluid pal-table-image"
                    />
                )
            }
        </>
    )
}