import { useEffect, useState } from "react";
import Spinner from "../../../common/components/Spinner";
import {
  Message,
  MessageResponse,
  UniqueMatch,
} from "../../../common/consts/types";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
  getMessagesAfterQuery,
  getMessagesQuery,
} from "../../../graphql/queries";
import InfiniteScroll from "react-infinite-scroll-component";

export default function MatchConversationExpand(props: {
  match?: UniqueMatch;
}) {
  const { match } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<null | string>(null);
  const [conversation, setConversation] = useState<Message[]>([]);

  const fetchConversation = async () => {
    try {
      setIsLoading(true);
      let response: { data: { messages: MessageResponse } };
      if (nextToken) {
        response = (await API.graphql(
          graphqlOperation(
            getMessagesAfterQuery(match?.matchId || "", nextToken)
          )
        )) as GraphQLResult as { data: { messages: MessageResponse } };
      } else {
        response = (await API.graphql(
          graphqlOperation(getMessagesQuery(match?.matchId || ""))
        )) as GraphQLResult as { data: { messages: MessageResponse } };
      }

      setIsLoading(false);
      const { messages } = response?.data;
      if (response && messages) {
        const messagesToSet = [
          ...(conversation || []),
          ...(messages?.messages || []),
        ];
        setConversation([...messagesToSet]);
        setNextToken(messages?.nextToken);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchConversation();
  }, []);

  return (
    <div className="ms-5 conversation-view">
      <InfiniteScroll
        dataLength={conversation.length}
        next={fetchConversation}
        hasMore={nextToken ? true : false}
        loader={<h4>Loading...</h4>}
        scrollThreshold={0.95}
        height={400}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {isLoading ? <Spinner /> : " "}
        {!isLoading && conversation?.length === 0 ? (
          <p>No Conversation!</p>
        ) : null}
        {conversation?.map((conv, index) => {
          return (
            <p className="text-dark" key={`${conv.body.displayName}${index}`}>
              {conv.body.displayName}: {conv.body.text}
            </p>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
