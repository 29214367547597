import React, { useEffect, useState } from "react";
import Spinner from "../../../common/components/Spinner";
import { getPhotoUrl } from "../../../utils/photoMiddleware";

export default function SinglePlacePhoto(props: {
  item: string;
  rangeKey: string;
}) {
  const [img, setImg] = useState<any>({ uri: "", headers: {} });
  const { item, rangeKey } = props;
  async function getDefaultPhoto(item: string) {
    try {
      if (rangeKey && item) {
        const response:any = await getPhotoUrl(rangeKey, item, "", "", "places");
        setImg(response);

        if(response?.uri){
          const res = await fetch(response?.uri, {
            headers: {
              ...response?.headers
            }
          })
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDefaultPhoto(item);
  }, [item, rangeKey]);

  return (
    <div>
      {img?.uri ? (
        <img
          src={img.uri}
          height="200"
          width="200"
          style={{ objectFit: "cover" }}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}
