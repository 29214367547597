import Activity from "../../../models/activity";
import Pal from "../../../models/pal";
import { startCase } from "lodash";
import ThisOrThat from "../../../models/thisThat";
import PalImage from "./PalImage";
import moment from "moment";

export default function UserTableExpand(props: {
  pal: Pal | undefined;
  rangeKey: string;
  defaultPhotoRefId?: string;
}) {
  const { pal, rangeKey, defaultPhotoRefId } = props;

  return (
    <div className="ms-5">
      <div className="pals-table-user-photos">
        {defaultPhotoRefId ? (
          <PalImage photoRefId={defaultPhotoRefId} rangeKey={rangeKey} />
        ) : null}
        {pal?.photoRefIds?.map((photoRefId: string, index) => {
          return (
            <PalImage key={index} photoRefId={photoRefId} rangeKey={rangeKey} />
          );
        })}
      </div>
      <p className="text-dark fs-20">
        Favorite Activities:{" "}
        {pal?.favActivities
          ? pal?.favActivities
              ?.map((ele: Activity) => startCase(ele.value))
              .join(", ")
          : "N/A"}
      </p>
      <p className="text-dark fs-20">
        Personality Traits:{" "}
        {pal?.thisOrThats
          ? pal?.thisOrThats
              ?.map((ele: ThisOrThat) => startCase(ele.thatOption))
              .join(", ")
          : "N/A"}
      </p>
      <p className="text-dark fs-20">Pal Nickname: {pal?.nickname || "N/A"}</p>
      <p className="text-dark fs-20">
        Pal Birthdate: {moment(pal?.birthDate).format("MM.DD.YYYY")}
      </p>
      <p className="text-dark fs-20">
        Pal Age: {moment().diff(pal?.birthDate, "years")}
      </p>
      <p className="text-dark fs-20">Pal Gender: {pal?.gender || "N/A"}</p>
      <p className="text-dark fs-20">Pal Size: {pal?.size || "N/A"}</p>
      <p className="text-dark fs-20">
        Pal Breeds: {pal?.breed?.join(", ") || "N/A"}
      </p>
      <p className="text-dark fs-20">Pal Bio: {pal?.bio || "N/A"}</p>
      <p className="text-dark fs-20">
        Pal Special Needs: {pal?.specialNeeds || "N/A"}
      </p>
      <p className="text-dark fs-20">
        Pal Registered Animal ID: {pal?.registeredAnimalId || "N/A"}
      </p>
      <p className="text-dark fs-20">
        Pal Registered License ID: {pal?.registeredLicenseNo || "N/A"}
      </p>
      <p className="text-dark fs-20">Instagram: {pal?.instagram || "N/A"}</p>
    </div>
  );
}
