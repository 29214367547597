import { Layout } from "antd";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { useCallback, useEffect } from "react";
import { getRole, logout } from "../../utils/auth";
import { Switch } from "react-router-dom";
import Users from "./components/Users";
import PrivateRoute from "../../routing/PrivateRoute";
import SideBar from "./components/SideBar";
import TextField from "../../common/components/TextField";
import Places from "./components/Places";
import Checkin from "./components/Checkin";
import Stats from "../Stats";
import { useDispatch } from "react-redux";
import { updateSearchQuery } from "../../redux/actions/searchActions";
import AllCharts from "./components/Chart";
import { saveRole } from "../../redux/actions/userActions";
import moment from "moment";
import Matches from "./components/Matches";

const { Header, Content } = Layout;

export default function Dashboard() {
  const checkSession = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        if (!user) {
          logout();
        }
      })
      .catch((error) => {
        logout();
      });
  }, []);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  const dispatch = useDispatch();

  function manageRole() {
    let cognitoUser: any = localStorage.getItem("cognitoUser");
    if (cognitoUser) {
      cognitoUser = JSON.parse(cognitoUser);
      const payload = cognitoUser?.signInUserSession?.idToken?.payload;
      if (payload && payload["cognito:groups"]) {
        const role = getRole(payload);
        if (role) {
          dispatch(saveRole(role));
        }
      }
    }
  }

  async function checkLoginTime() {
    try {
      const logTime = localStorage.getItem("loginTime");
      if (logTime) {
        const parsedLogTime = JSON.parse(logTime);
        const is24HourPrior = moment().diff(moment(parsedLogTime), "minutes");
        if (is24HourPrior > 1439) {
          logout();
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    manageRole();
    checkLoginTime();
  }, []);

  return (
    <Layout className="mh-100vh">
      <SideBar />
      <Layout className="bg-white">
        <Header className="pals-site-layout-header">
          <TextField
            containerClassName="ms-auto me-0 pals-header-search"
            rightIcon="search"
            inputClassName="plas-header-search-input"
            rightIconClassName="pals-header-serach-icon"
            inputProps={{
              placeholder: "Search...",
              onChange: (e: any) => {
                dispatch(updateSearchQuery(e.target.value));
              },
            }}
          />
        </Header>
        <Content style={{ margin: "24px 16px 0" }}>
          <Switch>
            <PrivateRoute
              redirectUrl="/login"
              path="/"
              exact
              component={Stats}
            />
            <PrivateRoute
              exact
              redirectUrl="/login"
              path="/users"
              component={Users}
            />
            <PrivateRoute
              exact
              redirectUrl="/login"
              path="/matches"
              component={Matches}
            />
            <PrivateRoute
              redirectUrl="/login"
              exact
              path="/pals-places"
              component={Places}
            />
            <PrivateRoute
              redirectUrl="/login"
              exact
              path="/pals-places/reported"
              component={Places}
              reported={true}
            />
            <PrivateRoute
              redirectUrl="/login"
              exact
              path="/checkin/history"
              component={Checkin}
              history={true}
            />
            <PrivateRoute
              redirectUrl="/login"
              exact
              path="/checkin/active"
              component={Checkin}
              active={true}
            />
            <PrivateRoute
              redirectUrl="/login"
              exact
              path="/charts"
              component={AllCharts}
              active={true}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
