import {
  MAPBOX_BASE_URL,
  MAPBOX_EXTENSION,
  MAPBOX_TOKEN,
} from "../common/consts/config";

export async function getAddress(long: number, lat: number, type?: string) {
  const url = `${MAPBOX_BASE_URL}${long},${lat}${MAPBOX_EXTENSION}?types=${
    type || "address"
  }&access_token=${MAPBOX_TOKEN}`;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return {
        success: true,
        place: response?.features[0]?.place_name,
        response,
      };
    })
    .catch((error) => {
      return {
        success: false,
        error,
        response: null,
      };
    });
}

export async function getCityStateFromZip(zip: string) {
  const url = `${MAPBOX_BASE_URL}${zip}${MAPBOX_EXTENSION}?limit=10&types=postcode&access_token=${MAPBOX_TOKEN}`;

  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      const allContexts = response?.features[0]?.context || [];
      return {
        success: true,
        place: (allContexts || [])?.find((e: any) => e.id?.includes("place"))
          ?.text,
        region: (allContexts || [])?.find((e: any) => e.id?.includes("region"))
          ?.text,
      };
    })
    .catch((error) => {
      return {
        success: false,
        error,
        response: null,
      };
    });
}
