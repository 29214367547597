import { useEffect, useState, useCallback, useRef } from "react";
import UserTableExpand from "./UserTableExpand";
import { useDispatch, useSelector } from "react-redux";
import { SearchReducer, UserReducer } from "../../../common/consts/types";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getAdminUsers } from "../../../graphql/queries";
import { saveUser } from "../../../redux/actions/userActions";
import User from "../../../models/user";
import moment from "moment";
import Pal from "../../../models/pal";
import PalTabs from "./PalTabs";
import RenderZip from "./RenderZip";
import { useIsMounted } from "../../../common/hooks/useIsMounted";
import Button2 from "../../../common/components/Button";
import { LIMIT_USERS_TABLE } from "../../../common/consts/config";
import { startCase } from "lodash";
import type { ColumnsType, ColumnType } from 'antd/lib/table';
import { Button, Input, Space, Table } from 'antd';
import type { InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';



type DataIndex = keyof User;

export default function Users() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const users = useSelector(
    (state: { User: UserReducer }) => state?.User?.users || []
  );

  const apiNextToken = useSelector(
    (state: { User: UserReducer }) => state?.User?.nextToken || ""
  );

  const dispatch = useDispatch();

  const isMounted = useIsMounted();

  const role = useSelector((state: { User: UserReducer }) => state.User?.role);

  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const searchQuery = useSelector(
    (state: { Search: SearchReducer }) => state.Search?.searchQuery
  );

  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    function filterResponse() {
      setTimeout(() => {
        const usersFiltered = users?.filter((e) => {
          return JSON.stringify(e)
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase());
        });
        setFilteredUsers(usersFiltered);
      }, 100);
    }
    filterResponse();
  }, [searchQuery, users]);

  const fetchUsers = useCallback(
    async (loadMore?: boolean) => {
      try {
        if (!users || users?.length === 0 || loadMore) {
          if (loadMore) setIsLoadingMore(true);
          else setIsLoading(true);
          const response = await (API.graphql(
            graphqlOperation(getAdminUsers(LIMIT_USERS_TABLE, apiNextToken))
          ) as GraphQLResult as {
            data: { adminUsers: { items: User[]; nextToken: string } };
          });
          const resUsers: User[] = response?.data?.adminUsers?.items || [];
          const nextToken: string = response?.data?.adminUsers?.nextToken;
          if (isMounted()) {
            if (resUsers) {
              let usersToSave = resUsers.slice();
              if (loadMore) {
                usersToSave = [...users, ...usersToSave];
              }
              usersToSave = usersToSave.sort(function (a, b) {
                return (
                  new Date(b.createdAt as any).getTime() -
                  new Date(a.createdAt as any).getTime()
                );
              });
              dispatch(saveUser(usersToSave, nextToken, false));
            }
            setIsLoading(false);
            setIsLoadingMore(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [users, dispatch, isMounted, apiNextToken]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);


  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<User> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const val = record[dataIndex]
      ?.toString()
      .toLowerCase()
      .includes((value as string).toLowerCase())
      return val ? val : false
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
        // text
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: User, b: User) =>
        moment(a?.createdAt).unix() - moment(b?.createdAt).unix(),
      render: (text: string) => {
        return <div>{moment(text).format("MM/DD/YYYY")}</div>;
      },
    },
    {
      title: "Owner",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps('name'),
      sorter: (a: any, b: any) => {
        return a?.name?.localeCompare(b?.name);
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getColumnSearchProps('phoneNumber'),
      render: (text: string) => {
        return <div>{role === "Intern" ? " - " : text}</div>;
      },
    },
    // {
    //   title: "Zip Code",
    //   dataIndex: "geoJson",
    //   key: "geoJson",
    //   render: (text: string) => {
    //     return <RenderZip text={text} />;
    //   },
    // },
    // {
    //   title: "City",
    //   dataIndex: "geoJson",
    //   key: "city",
    //   render: (text: string) => {
    //     return <RenderZip text={text} isCity={true} />;
    //   },
    // },
    // {
    //   title: "State",
    //   dataIndex: "geoJson",
    //   key: "state",
    //   render: (text: string) => {
    //     return <RenderZip text={text} isState={true} />;
    //   },
    // },
    {
      title: "Owner Birthdate",
      dataIndex: "birthDate",
      key: "ownderBirthDate",
      sorter: (a: User, b: User) =>
        moment(a?.birthDate).unix() - moment(b?.birthDate).unix(),
      render: (text: string) => {
        return (
          <div>
            {role === "Intern"
              ? moment().diff(moment(text), "years")
              : moment(text).format("MM/DD/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Owner Age",
      dataIndex: "birthDate",
      key: "ownderAge",
      sorter: (a: User, b: User) =>
        moment(a?.birthDate).unix() - moment(b?.birthDate).unix(),
      render: (text: string) => {
        return <div>{moment().diff(moment(text), "years")}</div>;
      },
    },
    {
      title: "Owner City",
      dataIndex: "city",
      key: "ownerCity",
      ...getColumnSearchProps('city'),
      sorter: (a: User, b: User) =>
        moment(a?.city).unix() - moment(b?.city).unix(),
      render: (text: any, record: User) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Owner State",
      dataIndex: "state",
      key: "ownerState",
      ...getColumnSearchProps('state'),
      sorter: (a: User, b: User) =>
        moment(a?.state).unix() - moment(b?.state).unix(),
      render: (text: any, record: User) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Owners Instagram",
      dataIndex: "userInstagram",
      key: "userInstagram",
      sorter: (a: any, b: any) => {
        return a?.name?.localeCompare(b?.userInstagram);
      },
    },
    {
      title: "Pal Name",
      dataIndex: "pals",
      key: "palName",
      ...getColumnSearchProps('pals'),
      sorter: (a: any, b: any) => {
        return a?.pals[0]?.name?.localeCompare(b?.pals[0]?.name);
      },
      render: (text: any, record: User) => {
        if (text?.length > 0) return <div>{text[0]?.name}</div>;
        return <></>;
      },
    },
    {
      title: "Profile Completed",
      dataIndex: "pals",
      key: "profileComplete",
      render: (text: any, record: User) => {
        if (text?.length > 0)
          return <div>{text[0]?.profileComplete ? "Yes" : "No"}</div>;
        return <></>;
      },
    },
    {
      title: "Radius",
      dataIndex: "radius",
      key: "radius",
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      render: (text: any) => {
        return <div>{text ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Notifications",
      dataIndex: "notifications",
      key: "notifications",
      render: (text: any) => {
        return <div>{text ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Total Swipes",
      dataIndex: "quickStats",
      key: "quickStats",
      sorter: (a: any, b: any) => {
        const c1 = a?.quickStats?.totalSwipes ? a?.quickStats?.totalSwipes : 0;
        const c2 = b?.quickStats?.totalSwipes ? b?.quickStats?.totalSwipes : 0;
        return c1 - c2;
      },
      render: (quickStats: any, record: User) => {
        if (quickStats?.totalSwipes) return <div>{quickStats?.totalSwipes}</div>;
        return <></>;
      },
    },
    {
      title: "Tour Status",
      dataIndex: "tourStatus",
      key: "tourStatus",
      render: (text: any) => {
        return <div>{startCase(text)}</div>;
      },
    },
    {
      title: "User ID",
      dataIndex: "rangeKey",
      key: "rangeKey",
      ...getColumnSearchProps('rangeKey'),
      render: (id: any) => {
        return <span>{id.substring(0,8)}</span>;
      }
    },
  ];
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Table
        columns={columns}
        className="table-striped-rows"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-dark" : "table-row-light"
        }
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: (record) => (
            <PalTabs
              pals={record.pals || []}
              userRangeKey={record.rangeKey || ""}
              defaultPhotoRefId={record?.defaultPhotoRefId || ""}
            />
          ),
          expandRowByClick: true,
          expandIcon: () => {
            return null;
          },
        }}
        pagination={{
          pageSize: 50,
        }}
        dataSource={users}
        loading={isLoading}
        rowKey="rangeKey"
      />
      {apiNextToken ? (
        <Button2
          title="Load More"
          containerClassName="pals-table-loadmore"
          titleClassName="pals-table-loadmore-text"
          isLoading={isLoadingMore}
          onClick={() => {
            fetchUsers(true);
          }}
        />
      ) : null}
    </div>
  );
}
