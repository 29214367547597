import {
    Route,
    Redirect
} from 'react-router-dom';

export default function PrivateRoute(props: any) {
    const { component: Component, redirectUrl, ...rest } = props;
    return (
        <Route
            {...rest}
            render={props =>
                (localStorage.getItem('cognitoUser')) ? (
                    <Component {...rest} {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectUrl,
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
}