function SackOutlined(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 146.14 146.14"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M146.13 117.6c0 15.76-14 28.54-31.31 28.54H31.33C14 146.14 0 133.36 0 117.6c0-5.7-2.82-50.34 53.07-84.51L37.26 9.91a8.11 8.11 0 01-.72-3.06A6.85 6.85 0 0143.39 0h59.37a6.86 6.86 0 016.83 6.85 8.12 8.12 0 01-.73 3.06L93.05 33.08c55.59 34 53.08 78.92 53.08 84.52zM83.66 43.39H62.51C23 67.52 13.49 94.48 13.73 117.6c0 8.18 7.9 14.84 17.6 14.84h83.49c9.71 0 17.6-6.66 17.6-14.84.2-23.14-9.49-50.19-48.76-74.21zM56.44 13.7l10.91 16H78.8l10.91-16z"
          data-name="Layer 1"
          fill={props?.color || "#002d3b"}
        />
      </g>
    </svg>
  );
}

export default SackOutlined;
