import { Stat } from "../../../common/consts/types";
import Countup from "react-countup";
export default function StatGridLayout(props: { stats: Stat[] }) {
  const colors = ["primary", "appcolor1", "appcolor2", "light"];
  const { stats } = props;
  return (
    <div className="container-fluid mt-4 mb-5">
      <div className="row">
        {stats?.map((stat, index) => {
          return <StatCard key={index} stat={stat} index={index} />;
        })}
      </div>
    </div>
  );
  function StatCard(cardProps: { stat: Stat; index: number }) {
    const { stat, index } = cardProps;
    const color = colors[index % colors.length];
    return (
      <div className="col-12 col-md-4 col-lg-3">
        <div className="d-flex bg-white shadow rounded">
          <div className={`p-4 bg-${color} d-flex align-items-center`}>
            <i className={`fas fa-${stat.icon}`} style={{ fontSize: "30px" }} />
          </div>
          <div className="p-3">
            <div className="font-weight-bold fs-20">{stat.title}</div>
            <div className="fs-18">
              <Countup duration={3} start={0} delay={0} end={stat.value} />{" "}
              {stat.totalValue ? (
                <>
                  <Countup
                    prefix="/ "
                    duration={3}
                    start={0}
                    delay={0}
                    end={stat.totalValue}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
