import { useCallback, useEffect, useState } from "react";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getUserRangeKey } from "../../../graphql/queries";

export default function RenderUserName(props: any) {
  const { d, index } = props;
  const [name, setName] = useState<string>("");
  const [ownerName, setOwnerName] = useState<string>("");
  const fetchUserNameFromRangeKey = useCallback(async (rangeKey: string) => {
    try {
      const response = (await API.graphql(
        graphqlOperation(getUserRangeKey(), {
          rangeKey,
        })
      )) as GraphQLResult as {
        data: {
          userRangeKey: {
            displayName: string;
            name: string;
            pals: {
              name: string;
            }[];
          }[];
        };
      };
      const name =
        response?.data?.userRangeKey?.length > 0
          ? response?.data?.userRangeKey[0]?.pals?.length > 0
            ? response?.data?.userRangeKey[0]?.pals[0]?.name
            : ""
          : "";
      const displayName =
        response?.data?.userRangeKey?.length > 0
          ? response?.data?.userRangeKey[0]?.name
          : "";

      if (name) {
        setName(name);
      }
      if (displayName) {
        setOwnerName(displayName);
      }
    } catch {}
  }, []);

  useEffect(() => {
    const name = d?.length > index ? d[index]?.userName : " - ";
    if (name) {
      setName(name);
    }
    fetchUserNameFromRangeKey(d[index]?.userId);
  }, []);
  return (
    <div>
      <div>Pal - {name}</div>
      <div>Owner - {ownerName}</div>
    </div>
  );
}
