import { UPDATE_SEARCH_QUERY } from "../actions/types";

const initialState = {
  searchQuery: "",
};

const SearchReducer = (
  state = initialState,
  action: {
    type: string;
    error: any;
    payload: any;
  }
) => {
  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default SearchReducer;
