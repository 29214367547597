function FenceFilled(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 156.04 157.59"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M105.64 48H94.1v-1.23-14a3.08 3.08 0 00-.33-1.47q-8-14.13-16.07-28.22c-.06-.1-.14-.19-.27-.37a6.58 6.58 0 00-.53.76q-7.82 13.66-15.6 27.37a4.24 4.24 0 00-.5 1.91V48H49.25v-1.25-14a3.08 3.08 0 00-.33-1.47Q40.88 17.13 32.83 3c-.06-.09-.15-.18-.3-.37-.25.4-.48.75-.69 1.11-5.08 8.92-10.18 17.84-15.22 26.79a5.81 5.81 0 00-.7 2.68c-.07 4.52 0 9 0 13.54 0 .39 0 .79-.06 1.27H2.5V66.3h12.21c.85 0 1.21.15 1.21 1.1v44.93c0 .27-.06.54-.1.92H2.51v18.25h13.34v23.56h33.37V131.6c.48 0 .86-.08 1.24-.08h9c1 0 1.36.25 1.36 1.33-.05 7 0 13.9 0 20.85v1.39h33.3v-23.47h11.5m.07 0v23.47H139v-23.53h14.57v-18.24h-14.48v-47h14.45V48.13H139v-1.36-13.9a4.37 4.37 0 00-.54-2q-7.77-13.73-15.59-27.4a6.68 6.68 0 01-.37-1h-.24c-2 3.57-4 7.15-6 10.71-3.34 5.88-6.71 11.75-10 17.65a4 4 0 00-.49 1.9V48M60.78 74.28v38.94H49.33V66.3h10.32c.9 0 1.17.28 1.15 1.17-.06 2.26-.02 4.53-.02 6.81zm44.85 38.48a3.18 3.18 0 01-.09.47H94.18v-46.8a5.23 5.23 0 01.78-.11h9.71c.71 0 1 .2 1 .95q-.03 22.73-.04 45.49z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
          data-name="Layer 1"
          fill={props.color || '#002d3b'}
        />
      </g>
    </svg>
  );
}

export default FenceFilled;