import { SAVE_MATCHES } from "../actions/types";

const initialState = {
  matches: [],
  nextToken: "",
  isAllMatches: false,
};

const MatchReducer = (
  state = initialState,
  action: {
    type: string;
    error: any;
    payload: any;
    nextToken: string;
    isAllMatches?: boolean;
  }
) => {
  switch (action.type) {
    case SAVE_MATCHES:
      return {
        ...state,
        matches: action.payload || [...state.matches],
        nextToken: action.nextToken,
        isAllMatches: action.isAllMatches || false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default MatchReducer;
