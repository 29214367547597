import { Select as SelectAntd } from "antd";

export default function Select(props: {
  options: {
    title: string;
    value: string;
  }[];
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}) {
  const { options, placeholder, value, onChange } = props;
  const { Option } = SelectAntd;
  return (
    <div className="pals-header-search w-100 h-auto my-2">
      <SelectAntd
        defaultValue=""
        value={value || ""}
        className="pals-dropdown w-100"
        onChange={onChange}
      >
        <Option value="">{placeholder || "Please Select"}</Option>
        {options?.map((option, index) => {
          return (
            <Option value={option.value} key={index}>
              {option.title}
            </Option>
          );
        })}
      </SelectAntd>
    </div>
  );
}
