import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  PlacesReducer,
  SearchReducer,
  UserReducer,
} from "../../../common/consts/types";
import { useCallback, useEffect, useState, useRef } from "react";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getAdminPlaces } from "../../../graphql/queries";
import moment from "moment";
import { PlaceItem, UserConfirmation } from "../../../models/placeInput";
import { savePlace } from "../../../redux/actions/placeActions";
import { startCase } from "lodash";
import PlaceTableExpand from "./PlaceTableExpand";
// import RenderZip from "./RenderZip";
import { useIsMounted } from "../../../common/hooks/useIsMounted";
// import { LIMIT_PLACES_TABLE } from "../../../common/consts/config";
import Button2 from "../../../common/components/Button";
import AddPlace from "../../AddPlace";
import { deletePlace } from "../../../graphql/mutations";
import type { InputRef } from "antd";
import type { ColumnsType, ColumnType } from "antd/lib/table";
import { Button, Input, Space } from "antd";
import type { FilterConfirmProps } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

type DataIndex = keyof PlaceItem;

export default function Places(props: { reported?: boolean }) {
  const { reported } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isAddingPlace, setIsAddingPlace] = useState<boolean>(false);
  const [editPlace, setEditPlace] = useState<PlaceItem | null>(null);
  const [editIndex, setEditIndex] = useState<number | -1>(-1);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteIndex, setIsDeleteIndex] = useState<number>(-1);

  const places = useSelector(
    (state: { Place: PlacesReducer }) => state?.Place?.places || []
  );
  const apiNextToken = useSelector(
    (state: { Place: PlacesReducer }) => state?.Place?.nextToken || ""
  );
  const role = useSelector((state: { User: UserReducer }) => state.User?.role);

  const dispatch = useDispatch();

  const isMounted = useIsMounted();

  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchQuery = useSelector(
    (state: { Search: SearchReducer }) => state.Search?.searchQuery
  );
  const isAllPlaces = useSelector(
    (state: { Place: PlacesReducer }) => state.Place?.isAllPlaces || false
  );

  const placesFiltered = places?.filter((e) => {
    return JSON.stringify(e)?.includes(searchQuery);
  });

  const fetchAllPlaces = useCallback(
    async (loadMore?: boolean) => {
      try {
        if (isAllPlaces) {
          setIsLoading(false);
        }

        if (!isAllPlaces) {
          setIsLoading(true);
          const response = await (API.graphql(
            graphqlOperation(getAdminPlaces(100, apiNextToken))
          ) as GraphQLResult as {
            data: { adminPlaces: { items: PlaceItem[]; nextToken: string } };
          });
          const resPlaces: PlaceItem[] =
            response?.data?.adminPlaces?.items || [];
          const nextToken: string = response?.data?.adminPlaces?.nextToken;

          if (isMounted()) {
            if (resPlaces) {
              let placesToSave = [...places, ...(resPlaces || [])?.slice()];

              dispatch(
                savePlace(placesToSave, nextToken, nextToken ? false : true)
              );
            }
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [dispatch, isMounted, isAllPlaces, apiNextToken, places]
  );

  useEffect(() => {
    fetchAllPlaces();
  }, [fetchAllPlaces]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<PlaceItem> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const val = record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
      return val ? val : false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        // text
        text
      ),
  });

  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Place ID",
      dataIndex: "rangeKey",
      key: "rangeKey",
      ...getColumnSearchProps('rangeKey'),
      render: (id: any) => {
        return <span>{id.substring(0,8)}</span>;
      }
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: 'descend',
      sorter: (a: PlaceItem, b: PlaceItem) =>
        moment(a?.createdAt).unix() - moment(b?.createdAt).unix(),
      render: (text: string) => {
        return <div>{moment(text).format("MM/DD/YYYY")}</div>;
      },
    },
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a: any, b: any) => {
        return a?.name?.localeCompare(b?.name);
      },
    },
    // {
    //   title: "Zip Code",
    //   dataIndex: "geoJson",
    //   key: "geoJson",
    //   render: (text: string) => {
    //     return <RenderZip text={text} />;
    //   },
    // },
    {
      title: "Type",
      dataIndex: "category",
      key: "category",
      ...getColumnSearchProps("category"),
      sorter: (a: any, b: any) => {
        return a?.category?.localeCompare(b?.category);
      },
      render: (text: string) => {
        return <div>{startCase(text)}</div>;
      },
    },
    {
      title: "Likes",
      dataIndex: "verified",
      key: "likes",
      sorter: (a: any, b: any) => a?.verified?.length - b?.verified?.length,
      render: (text: any, record: PlaceItem) => {
        return <div>{text?.length || 0}</div>;
      },
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      sorter: (a: any, b: any) => a?.verified?.length - b?.verified?.length,
      render: (text: any, record: PlaceItem) => {
        return (
          <div>
            {text?.filter((e: UserConfirmation) => e.confirmed)?.length >= 10
              ? "YES"
              : "NO"}
          </div>
        );
      },
    },
    {
      title: "Admin Verified",
      dataIndex: "adminVerified",
      key: "adminVerified",
      sorter: (a: any, b: any) => a?.adminVerified?.confirmed - b?.adminVerified?.confirmed,
      render: (text: any, record: PlaceItem) => {
        return (
          <div>
            {text?.confirmed
              ? "YES"
              : "NO"}
          </div>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, record: PlaceItem, index: number) => {
        if (!reported)
          return (
            <Button2
              containerClassName="pals-table-loadmore"
              titleClassName="pals-table-loadmore-text"
              title="Delete"
              disabled={role === "Intern"}
              isLoading={isDeleting && isDeleteIndex === index}
              onClick={(e) => {
                e.stopPropagation();
                const confirmation = window.confirm(
                  "Are you sure you want to delete"
                );
                if (confirmation) {
                  deleteLocation(index, record);
                }
              }}
            />
          );
        return null;
      },
    },
  ];

  const reportedColumns = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        return a?.name?.localeCompare(b?.name);
      },
    },
    {
      title: "Location Range Key",
      dataIndex: "rangeKey",
      key: "rangeKey",
    },
  ];

  async function deleteLocation(index: number, record: PlaceItem) {
    try {
      setIsDeleting(true);
      setIsDeleteIndex(index);
      await API.graphql(
        graphqlOperation(deletePlace(record.rangeKey, record.hashKey as number))
      );
      const newPlaces = places?.slice();
      newPlaces.splice(index, 1);
      dispatch(savePlace(newPlaces, apiNextToken, false));
      setIsDeleting(false);
      setIsDeleteIndex(-1);
    } catch (error) {
      setIsDeleting(false);
      setIsDeleteIndex(-1);
    }
  }

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Button2
        title="Add Place"
        containerClassName="pals-table-loadmore ms-auto me-0"
        titleClassName="pals-table-loadmore-text"
        onClick={() => {
          setIsAddingPlace(true);
        }}
      />
      <Table
        columns={reported ? reportedColumns : (columns as any)}
        className="table-striped-rows"
        scroll={{ x: true }}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-dark" : "table-row-light"
        }
        pagination={{
          defaultPageSize: 50,
        }}
        expandable={{
          expandedRowRender: (record, index) => (
            <PlaceTableExpand
              fetchAllPlaces={fetchAllPlaces}
              setEditIndex={setEditIndex}
              setIsAddingPlace={setIsAddingPlace}
              place={record}
              reported={reported}
              setEditPlace={setEditPlace}
              editIndex={index}
            />
          ),
          expandRowByClick: true,
          expandIcon: () => {
            return null;
          },
        }}
        dataSource={reported ? getFlaggedPlaces() : placesFiltered}
        loading={isLoading}
        rowKey="rangeKey"
      />
      {/* {apiNextToken && !reported ? (
        <Button
          title="Load More"
          containerClassName="pals-table-loadmore"
          titleClassName="pals-table-loadmore-text"
          isLoading={isLoadingMore}
          onClick={() => {
            fetchPlaces(true);
          }}
        />
      ) : null} */}
      <AddPlace
        setEditPlace={setEditPlace}
        editPlace={editPlace}
        isModalVisible={isAddingPlace}
        onAddSuccess={(location) => {
          const placesToSave = [{ ...location }, ...places];
          dispatch(savePlace(placesToSave, apiNextToken, false));
        }}
        onEditSuccess={(location) => {
          let placesToSave = places?.slice();
          placesToSave[editIndex] = {
            ...location,
          };
          dispatch(savePlace(placesToSave, apiNextToken, false));
        }}
        closeModal={() => {
          setIsAddingPlace(false);
        }}
      />
    </div>
  );
  function getFlaggedPlaces() {
    return places.filter((place) => checkPlaceFlaggedOrNot(place.flagged));
  }
  function checkPlaceFlaggedOrNot(flagged: UserConfirmation[]) {
    let placeFlagged = false;
    for (const flag of flagged) {
      if (flag?.confirmed) {
        placeFlagged = true;
        break;
      }
    }
    return placeFlagged;
  }
}
