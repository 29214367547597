import { combineReducers } from 'redux';
import PlacesReducer from './PlacesReducer';
import UserReducer from './UserReducer';
import MatchReducer from './MatchReducer';
import SearchReducer from './SearchReducer';

export default combineReducers({
    User: UserReducer,
    Place: PlacesReducer,
    Match: MatchReducer,
    Search: SearchReducer
});