import { Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../common/components/Button";
import Error from "../../common/components/Error";
import TextField from "../../common/components/TextField";
import Select from "../../common/components/Select";
import { MAP_CATEGORIES } from "../../common/consts/PlaceConstants";
import Amenities from "./Amenities";
import {
  MutationPlaceInput,
  PalPlaceAmenities,
  PalPlaceCategory,
  PlaceItem,
} from "../../models/placeInput";
import { Coordinate } from "../../common/consts/types";
import {
  ADD_PLACE_RANGE_KEY,
  POINT_TYPE,
  USER_OPERATORS,
} from "../../common/consts/config";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import messages from "../../common/consts/messages";
import { getAddress } from "../../utils/mapbox";
import {
  addPlace as addPlaceMutation,
  updatePlace,
  uploadPhotosPlace,
} from "../../graphql/mutations";
import PlacePhoto from "./PlacePhoto";
import { Auth } from "aws-amplify";
import {
  convertBase64ToMd5Hash,
  getBase64,
  isFile,
} from "../../utils/commonFunc";
import { PhotoModel } from "../../models/photo";
import { uploadPlacePhoto } from "../../utils/photoMiddleware";

export default function AddPlace(props: {
  isModalVisible: boolean;
  onAddSuccess?: (location: PlaceItem) => void;
  onEditSuccess?: (location: PlaceItem) => void;
  closeModal?: () => void;
  editPlace: null | PlaceItem;
  setEditPlace: (editPlace: PlaceItem | null) => void;
}) {
  const {
    isModalVisible,
    closeModal,
    onAddSuccess,
    setEditPlace,
    editPlace,
    onEditSuccess,
  } = props;

  const [error, setError] = useState<string>("");

  const [amenities, setAmenities] = useState<PalPlaceAmenities[]>([]);
  const [category, setCategory] = useState<PalPlaceCategory>("OpenSpace");
  const [locationName, setLocationName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [location, setLocation] = useState<Coordinate>({
    longitude: "",
    latitude: "",
  });
  const [isValidationFired, setIsValidationFired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [photos, setPhotos] = useState<any[]>([]);

  const getLatestAddress = useCallback(async () => {
    if (location.latitude && location.longitude) {
      const result: any = await getAddress(
        +location.longitude,
        +location.latitude
      );
      if (result.success) {
        setAddress(result.place);
      }
    }
  }, [location]);

  function validation(firstTime?: boolean) {
    let validated = false;
    if (isValidationFired || firstTime) {
      let error = "";
      if (!locationName) {
        error = messages.locationNameReq;
      } else if (!location.longitude) {
        error = messages.longReq;
      } else if (!location.latitude) {
        error = messages.latReq;
      } else if (!category) {
        error = messages.categoryReq;
      } else if (!description) {
        error = messages.descReq;
      } else if (!address) {
        error = messages.addressReq;
      } else {
        validated = true;
        error = "";
      }
      setError(error);
    }
    return validated;
  }

  useEffect(() => {
    getLatestAddress();
  }, [getLatestAddress]);

  useEffect(() => {
    validation();
  }, [
    isValidationFired,
    category,
    description,
    location.latitude,
    location.longitude,
    locationName,
  ]);

  useEffect(() => {
    if (editPlace) {
      setPhotos(editPlace?.photoRefs);
      setAddress(editPlace?.address || "");
      setAmenities(editPlace?.amenities || []);
      setCategory(editPlace?.category || "");
      setLocation({
        ...location,
        ...editPlace?.coordinate,
      });
      setDescription(editPlace?.description || "");
      setLocationName(editPlace?.name || "");
    }
  }, [editPlace]);

  const allPhotos = [0, 1, 2, 3, 4, 5];

  return (
    <Modal
      title={`${editPlace ? "Edit" : "Add"} Place`}
      onCancel={() => {
        if (closeModal) {
          resetFields();
          closeModal();
        }
      }}
      width={800}
      visible={isModalVisible}
      footer={null}
    >
      <div className="row">
        <div className="col-12">
          <TextField
            containerClassName="pals-header-search w-100 mw-100"
            inputClassName="plas-header-search-input"
            rightIconClassName="pals-header-serach-icon"
            inputProps={{
              placeholder: "Location Name",
              value: locationName,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setLocationName(e.target.value);
              },
            }}
          />
        </div>
        <div className="col-12 col-md-6">
          <TextField
            containerClassName="pals-header-search w-100"
            inputClassName="plas-header-search-input"
            rightIconClassName="pals-header-serach-icon"
            inputProps={{
              placeholder: "Longitude",
              value: location.longitude,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setLocation({
                  ...location,
                  longitude: +e.target.value,
                });
              },
              disabled: editPlace,
            }}
            type="number"
          />
        </div>
        <div className="col-12 col-md-6">
          <TextField
            containerClassName="pals-header-search w-100"
            inputClassName="plas-header-search-input"
            rightIconClassName="pals-header-serach-icon"
            inputProps={{
              placeholder: "Latitude",
              value: location.latitude,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setLocation({
                  ...location,
                  latitude: +e.target.value,
                });
              },
              disabled: editPlace,
            }}
            type="number"
          />
        </div>
        <div className="col-12">
          <Select
            onChange={(value) => {
              setCategory(value as PalPlaceCategory);
            }}
            placeholder="Select Category"
            options={MAP_CATEGORIES}
            value={category}
          />
        </div>
        <div className="col-12">
          <TextField
            containerClassName="pals-header-search w-100 h-auto"
            inputClassName="plas-header-search-input"
            rightIconClassName="pals-header-serach-icon"
            rightIcon="map-marker-alt"
            inputProps={{
              placeholder: "Description",
              maxLength: 140,
              value: description,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
              },
            }}
            type="textarea"
          />
        </div>
        <div className="col-12 my-3">
          <Amenities
            selectedAmenities={amenities}
            onChange={(amenity: PalPlaceAmenities) => {
              if (amenities?.includes(amenity)) {
                const spliceIndex = amenities?.findIndex(
                  (val) => val === amenity
                );
                const newAmenities = [...amenities];
                newAmenities?.splice(spliceIndex, 1);
                setAmenities(newAmenities);
              } else {
                setAmenities([...amenities, amenity]);
              }
            }}
          />
        </div>
        <div className="d-flex justify-content-between flex-wrap mt-5">
          {allPhotos?.map((photo, index) => {
            const currentPhoto = photos[photo];
            return (
              <PlacePhoto
                key={index}
                index={index}
                photos={photos}
                setPhotos={setPhotos}
                rangeKey={editPlace?.rangeKey || ""}
                currentPhoto={currentPhoto}
              />
            );
          })}
        </div>
        <div className="col-12 my-3 fs-20 text-center">{address}</div>
        <div className="col-12">
          <Error error={error} />
          <Button
            isLoading={isLoading}
            title="Submit"
            onClick={addPlace}
            containerClassName="pals-table-loadmore"
            titleClassName="pals-table-loadmore-text"
          />
        </div>
      </div>
    </Modal>
  );

  function resetFields() {
    setAmenities([]);
    setCategory("OpenSpace");
    setLocationName("");
    setDescription("");
    setAddress("");
    setLocation({
      ...location,
      longitude: "",
      latitude: "",
    });
    setIsValidationFired(false);
    setError("");
    setEditPlace(null);
    setPhotos([]);
  }

  async function editPlaceMutation(body: MutationPlaceInput) {
    try {
      if (editPlace) {
        let updateBody = {
          ...body,
          createdAt: editPlace.createdAt,
          geoJson: editPlace.geoJson,
          geohash: editPlace.geohash,
          hashKey: editPlace.hashKey,
          rangeKey: editPlace.rangeKey,
        };
        delete updateBody.userOperator;
        delete updateBody.pointType;
        if (editPlace?.flagged) {
          updateBody.flagged = [...editPlace?.flagged];
        }
        if (editPlace?.verified) {
          updateBody.verified = [...editPlace?.verified];
        }
        if (editPlace?.createdBy) {
          updateBody.createdBy = editPlace?.createdBy;
        }

        const qlResponse = (await API.graphql(
          graphqlOperation(updatePlace(), { input: { ...updateBody } })
        )) as GraphQLResult as { data: { updatePlace: PlaceItem } };
        if (qlResponse?.data?.updatePlace) {
          const rKey = qlResponse?.data?.updatePlace?.rangeKey;
          const hKey = qlResponse?.data?.updatePlace?.hashKey;
          let fHashes: any[] = [];
          if (rKey && hKey) {
            fHashes = (await addPhotos(rKey, hKey)) || [];
          }
          if (onEditSuccess) {
            resetFields();
            onEditSuccess({
              ...qlResponse?.data?.updatePlace,
              photoRefs: fHashes,
            });
          }
          if (closeModal) {
            closeModal();
          }
        }
      }
    } catch (error) {}
  }

  async function addPhotos(rKey: string, hKey: number) {
    try {
      const user = await Auth.currentSession();

      const allPromises = photos.map(async (photo) => {
        if (isFile(photo)) {
          const base64: any = await getBase64(photo);
          let hash: string | Int32Array = convertBase64ToMd5Hash(base64);
          hash = hash.toString();
          const body: PhotoModel = {
            photoBase64: base64,
            hashOfPhoto: hash,
            folder: rKey ? `places/${rKey}` : "",
          };
          return uploadPlacePhoto(body, user.getIdToken().getJwtToken());
        }
      });
      return Promise.all(allPromises)
        .then((response: any) => {
          const previousHashes = photos?.filter((ele) => {
            if (!isFile(ele)) {
              return ele;
            }
          });
          const newHashes =
            response?.flatMap((ele: any) => ele?.hashOfPhoto) || [];
          const finalHashes = [...previousHashes, ...newHashes]?.filter(
            (ele) => ele
          );
          uploadHash(finalHashes, rKey, hKey);
          return finalHashes;
        })
        .catch((error) => {});
    } catch (error) {}
  }

  async function uploadHash(hash: any, rangeKey: string, hashKey: number) {
    try {
      const qlResponse = await API.graphql(
        graphqlOperation(uploadPhotosPlace(), {
          input: {
            hashKey: hashKey,
            rangeKey: rangeKey,
            photoRefs: hash,
          },
        })
      );
    } catch (error) {}
  }

  async function addPlace() {
    try {
      setIsValidationFired(true);
      const validated = validation(true);
      if (validated) {
        setIsLoading(true);
        const body: MutationPlaceInput = {
          amenities: amenities,
          category: category,
          userOperator: USER_OPERATORS.ADD,
          pointType: POINT_TYPE.Place,
          verified: [],
          name: locationName,
          flagged: [],
          description: description,
          createdBy: ADD_PLACE_RANGE_KEY,
          coordinate: {
            longitude: +location?.longitude,
            latitude: +location?.latitude,
          },
          address: address,
        };
        if (editPlace) {
          await editPlaceMutation(body);
        } else {
          const qlResponse = (await API.graphql(
            graphqlOperation(addPlaceMutation(), { input: { ...body } })
          )) as GraphQLResult as { data: { addPlace: PlaceItem } };
          if (qlResponse?.data?.addPlace) {
            const rKey = qlResponse?.data?.addPlace?.rangeKey;
            const hKey = qlResponse?.data?.addPlace?.hashKey;
            let fHashes: any[] = [];
            if (rKey && hKey) {
              fHashes = (await addPhotos(rKey, hKey)) || [];
            }
            if (onAddSuccess) {
              resetFields();
              onAddSuccess({
                ...qlResponse?.data?.addPlace,
                photoRefs: fHashes,
              });
            }
            if (closeModal) {
              closeModal();
            }
          }
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }
}
