export default function Error(props: { className?: string; error: string }) {
  const { className, error } = props;

  if (error)
    return (
      <div className={`pals-error fs-14 text-center mt-2 ${className}`}>
        {error}
      </div>
    );
  return <></>;
}
