import moment, { Moment } from "moment";
import { DATE_FORMAT } from "../common/consts/config";
import { PlaceItem } from "../models/placeInput";
import User from "../models/user";

export function generateUsersOverTime(
  allUsers: User[],
  startDate?: Moment,
  endDate?: Moment
) {
  const labels: string[] = [];
  const data: number[] = [];
  let tempData: any = {};
  for (const user of allUsers) {
    const createdDate = moment(user.createdAt).format(DATE_FORMAT);
    if (tempData[createdDate]) {
      tempData[createdDate] += 1;
    } else {
      tempData[createdDate] = 1;
    }
  }

  const sortedTempData = sortDateKeysOfObject(tempData);

  // eslint-disable-next-line array-callback-return
  Object.keys(sortedTempData)?.map((key) => {
    if (startDate && endDate) {
      if (
        moment(key, DATE_FORMAT).isSameOrAfter(startDate) &&
        moment(key, DATE_FORMAT).isSameOrBefore(endDate)
      ) {
        labels.push(key);
        data.push(sortedTempData[key]);
      }
    } else {
      labels.push(key);
      data.push(sortedTempData[key]);
    }
  });

  let average = data?.reduce((partialSum, a) => partialSum + a, 0);
  average = average / data.length;

  return {
    data: data || [],
    labels: labels || [],
    average,
  };
}

export function generatePlacesOverTime(
  allPlaces: PlaceItem[],
  startDate?: Moment,
  endDate?: Moment
) {
  const labels: string[] = [];
  const data: number[] = [];
  let tempData: any = {};
  for (const place of allPlaces) {
    const createdDate = moment(place.createdAt).format(DATE_FORMAT);
    if (tempData[createdDate]) {
      tempData[createdDate] += 1;
    } else {
      tempData[createdDate] = 1;
    }
  }

  // eslint-disable-next-line array-callback-return
  const sortedTempData = sortDateKeysOfObject(tempData);
  Object.keys(sortedTempData)?.map((key) => {
    if (startDate && endDate) {
      if (
        moment(key, DATE_FORMAT).isSameOrAfter(startDate) &&
        moment(key, DATE_FORMAT).isSameOrBefore(endDate)
      ) {
        labels.push(key);
        data.push(sortedTempData[key]);
      }
    } else {
      labels.push(key);
      data.push(sortedTempData[key]);
    }
  });
  let average = data?.reduce((partialSum, a) => partialSum + a, 0);
  average = average / data.length;

  return {
    data,
    labels,
    average,
  };
}

export function generateUsersPerState(allUsers: User[]) {
  const labels: string[] = [];
  const data: number[] = [];

  let tempData: any = {};
  for (const user of allUsers) {
    if (user.state) {
      if (tempData[user.state]) {
        tempData[user.state] += 1;
      } else {
        tempData[user.state] = 1;
      }
    }
  }

  // eslint-disable-next-line array-callback-return
  Object.keys(tempData)?.map((key) => {
    labels.push(key);
    data.push(tempData[key]);
  });

  return {
    data,
    labels,
  };
}

export function generateCitiesDataForState(allUsers: User[], state: string) {
  const labels: string[] = [];
  const data: number[] = [];

  let tempData: any = {};
  for (const user of allUsers?.filter((e) => e.state === state) || []) {
    if (user.city) {
      if (tempData[user.city]) {
        tempData[user.city] += 1;
      } else {
        tempData[user.city] = 1;
      }
    }
  }

  // eslint-disable-next-line array-callback-return
  Object.keys(tempData)?.map((key) => {
    labels.push(key);
    data.push(tempData[key]);
  });

  return {
    data,
    labels,
  };
}

function sortDateKeysOfObject(myDateObj: { [key: string]: string }) {
  var orderedDates: any = {};
  Object.keys(myDateObj)
    .sort(function (a, b) {
      return (
        +moment(a, DATE_FORMAT).toDate() - +moment(b, DATE_FORMAT).toDate()
      );
    })
    .forEach(function (key) {
      orderedDates[key] = myDateObj[key];
    });
  return orderedDates;
}
