const SideBarActive = [
  {
    key: "0",
    paths: ["/"],
  },
  {
    key: "1",
    paths: ["/users", "/users/reported"],
  },
  {
    key: "2",
    paths: ["/pals-places", "/pals-places/reported"],
  },
  {
    key: "3",
    paths: ["/checkin/active", "/checkin/history"],
  },
];

export default SideBarActive;
