import { PointType, UserOperator } from "../../models/placeInput";

export const IMAGE_BASE_64 = "data:image/jpeg;base64,";
export const MAPBOX_TOKEN: string =
  "pk.eyJ1IjoiZmVsZG1hbno2NiIsImEiOiJja28xcTUyeDQwbm9hMnZxd3h1YncxNDRoIn0.BZuPQjh_sx3klFsihLRQcA";
export const MAPBOX_EXTENSION: string = ".json";
export const MAPBOX_BASE_URL: string =
  "https://api.mapbox.com/geocoding/v5/mapbox.places/";
export const LIMIT_USERS_TABLE = 50;
export const LIMIT_PLACES_TABLE = 10;
export const MAPBOX_STATIC_IMAGE_BASE_URL: string =
  "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson";
export const MAPBOX_STATIC_IMAGE_ZOOM: string = "14";
export const MAPBOX_STATIC_IMAGE_SIZE: string = "600x600";
export const POINT_TYPE: { [key: string]: PointType } = {
  Place: "Place",
  User: "User",
};
export const ADD_PLACE_RANGE_KEY = "11111111-1111-1111-1111-111111111111";
export const USER_OPERATORS: { [key: string]: UserOperator } = {
  QUERY_RADIUS_FILTER_NOS: "QUERY_RADIUS_FILTER_NOS",
  ADD: "ADD",
  QUERY_RADIUS: "QUERY_RADIUS",
};
export const DATE_FORMAT = "DD-MM-YYYY";
export const PI_CHART_BGS = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
];

export const PI_CHART_BOGS = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];
