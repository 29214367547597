export function logout() {
  localStorage.removeItem("cognitoUser");
  window.location.replace("/login");
}

export function getRole(sessionInfo: any) {
  let role = "";
  console.log(sessionInfo["cognito:groups"])
  if (sessionInfo["cognito:groups"]?.includes("Intern")) {
    role = "Intern";
  } else if (sessionInfo["cognito:groups"]?.includes("Admin")) {
    role = "Admin";
  } else if (sessionInfo["cognito:groups"]?.includes("SuperAdmin")) {
    role = "SuperAdmin";
  }
  return role;
}
