import { useEffect, useState } from "react";
import { isFile } from "../../utils/commonFunc";
import { getPhotoUrl } from "../../utils/photoMiddleware";

export default function PlacePhoto(props: {
  currentPhoto: string;
  setPhotos: (photos: any[]) => void;
  photos: any[];
  index: number;
  rangeKey: string;
}) {
  const { currentPhoto, setPhotos, photos, index, rangeKey } = props;

  const [photoDetails, setPhotoDetails] = useState<any>({
    uri: "",
    headers: {},
  });

  function removePhoto() {
    const newPhotos = photos?.slice();
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  }

  async function generateUrl() {
    try {
      if (rangeKey && currentPhoto) {
        const response = await getPhotoUrl(
          rangeKey,
          currentPhoto,
          "",
          "",
          "places"
        );
        setPhotoDetails(response);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (!isFile(currentPhoto)) {
      generateUrl();
    }
  }, [currentPhoto, rangeKey]);

  return (
    <div
      className="pals-place-photo"
      onClick={() => {
        if (!currentPhoto) {
          document.getElementById("photo_upload_button")?.click();
        }
      }}
    >
      {!currentPhoto ? (
        <>
          <i className="fas fa-upload fa-lg text-dark" />
          <input
            type="file"
            accept="image/*"
            className="d-none"
            id="photo_upload_button"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                setPhotos([...photos, e.target.files[0]]);
              }
            }}
          />
        </>
      ) : (
        <>
          <div className="pals-delete-place-photo" onClick={removePhoto}>
            <i
              className="fas fa-times text-white"
              style={{ marginTop: "3px" }}
            />
          </div>
          <img
            src={
              isFile(currentPhoto)
                ? URL.createObjectURL(currentPhoto)
                : photoDetails?.uri
            }
            alt="pals-place"
            className="pals-place-image"
          />
        </>
      )}
    </div>
  );
}
